import React from 'react';
import Dialog from "../../UI/molecules/Dialog";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { BodyRegular } from "../../UI/atoms/fonts/Body";


export function CannotEditApprovedHoliday() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <Wrapper>
      <Dialog isOpen={true} onClose={close}>
        <ImageWrapper>
          <Image src={"/assets/icons/standard/Holiday.svg"} alt={""} />
        </ImageWrapper>
        <HeadlineMedium style={{marginBottom: 16, marginTop: 16}}>{t('dialog.cannot-edit-approved-holiday-title')}</HeadlineMedium>
        <BodyRegular style={{marginBottom: 16}}>{t('dialog.cannot-edit-approved-holiday-para1')}</BodyRegular>
        <ul style={{marginBottom: 24}}>
          <li><BodyRegular>1. Go to the <strong>Leave Planner</strong></BodyRegular></li>
          <li><BodyRegular>2. Go to the <strong>Holidays Tab</strong></BodyRegular></li>
          <li><BodyRegular>3. Tap the <strong>Edit Pencil</strong> on the holiday you wish to cancel</BodyRegular></li>
          <li><BodyRegular>4. <strong>Delete Holiday request</strong></BodyRegular></li>
        </ul>
        <BodyRegular style={{marginBottom: 24}}>{t('dialog.cannot-edit-approved-holiday-para2')}</BodyRegular>
        <PrimaryButton click={close} fullWidth={true} size={'large'} text={"button.done"} />
      </Dialog>
    </Wrapper>
  )
}

const ImageWrapper = styled.div`
  margin: 0 auto;
  background-color: ${Colours.veryLightGrey};
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Wrapper = styled.div`
  .dialog {
    width: 500px;
  }
  .dialog__content {
    width: 100%;
    margin: unset;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .dialog__contentContainer {
    padding: 8px;
  }
`

const Image = styled.img`
  width: 80%;
  height: 80%;
`

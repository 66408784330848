import { agent } from './agent';

export async function updateUserRequest(userId: string,
                                        firstname: string,
                                        lastname: string,
                                        teamId: string,
                                        fireWarden: boolean,
                                        firstAider: boolean,
                                        mentalHealthAider: boolean,
                                        keyHolder: boolean,
                                        holidayAllowance: number,
                                        enableOutlookSync: boolean) {
  return await agent.requests.put('/api/user', {
    userId: userId,
    firstName: firstname,
    lastName: lastname,
    fireWarden,
    firstAider,
    mentalHealthAider,
    keyHolder,
    holidayAllowance,
    enableOutlookSync,
    teamId: teamId,
  }, true);
}

export async function updateUsersTeam(userId: string, teamId: string) {
  return await agent.requests.put('/api/user', {
    userId, teamId,
  });
}

export async function getAllUsersRequest() {
  return await agent.requests.get('/api/user/all', true);
}

export async function findUserRequest(query: any) {
  if (!query || query.length < 3) {
    return [];
  }
  return await agent.requests.get(`/api/user-search?q=${query}`)
}

export async function updateOutlookSyncState(state: any) {
  return await agent.requests.put('/api/user/outlook-sync', {
    outlookSyncState: state
  })
}

export async function unsubscribeRequest(id: any) {
  return await agent.requests.post('/api/unsubscribe', {id: id});
}

export async function updateWhereaboutsRemindersRequest(allowWhereaboutsReminders: any, allowMarketingEmails: any) {
  return await agent.requests.put('/api/user/email-reminders', {
    allowWhereaboutsReminders,
    allowMarketingEmails
  });
}

export function isVisitorUser(userId?: string): boolean {
  if (!userId) return false;
  return userId.startsWith('visitor-id-')
}

export enum UpdateUserField {
  defaultOfficeId = 'DEFAULT_OFFICE_ID',
  carMake = 'CAR_MAKE',
  carRegistration = 'CAR_REGISTRATION',
  carColour = 'CAR_COLOUR',
  parkingEnabled = 'PARKING_ENABLED',
  accessPermitted = 'ACCESS_PERMITTED',
  team = 'TEAM',
  department = 'DEPARTMENT',
  birthdayMonth = 'BIRTHDAY_MONTH',
  birthdayDay = 'BIRTHDAY_DAY',
  jobTitle = 'JOB_TITLE',
}

export async function updateUserField(userId: string, key: UpdateUserField, value: any) {
  return await agent.requests.post('/api/user/field', {
    userId, key, value
  }, true);
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from "../../../../store/state/app.state";
import { Department } from "./departments.models";
import { requestCreateNewDepartment, requestUpdateDepartment } from "./departmentsService";
import { loadConfig } from "../../../../store/ducks/config.duck";
import { failureNotification, successNotification } from "../../../../store/ducks/notification.duck";


export interface DepartmentsState {
  departments: Department[]
  loading: boolean;
}

export const initialDepartmentsState: DepartmentsState = {
  departments: [],
  loading: false,
};

export const createNewDepartment: any = createAsyncThunk(
  'departments/createNewDepartment',
  async (params: {name: string}, thunkAPI) => {
    try {
      await requestCreateNewDepartment(params.name);
      thunkAPI.dispatch(successNotification('Department created'))
      thunkAPI.dispatch(loadConfig())
    } catch (e) {
      thunkAPI.dispatch(failureNotification('Department failed to be created'))
      throw e;
    }
  }
);

export const updateDepartment: any = createAsyncThunk(
  'departments/updateDepartment',
  async (department: Department, thunkAPI) => {
    await requestUpdateDepartment(department);
    thunkAPI.dispatch(loadConfig())
  }
);


const departmentsSlice = createSlice({
  name: 'departments',
  initialState: initialDepartmentsState,
  reducers: {
    resetDepartments: (state) => (initialDepartmentsState),
    setDepartments: (state, action) => ({...state, departments: action.payload}),
  },
  extraReducers: {
    // [getCarParkDetails.pending]: (state) => ({...state, carParkDetails: [], loading: true}),
    // [getCarParkDetails.failed]: (state) => ({...state, carParkDetails: [], loading: false}),
    // [getCarParkDetails.fulfilled]: (state, action) => ({...state, carParkDetails: action.payload.carParkDetails, loading: false}),
  },
});



export default departmentsSlice.reducer;
export const {
  resetDepartments, setDepartments,
} = departmentsSlice.actions;


// Selectors
export const selectAllDepartments = (state: AppState) => state.departments.departments;

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge, HeadlineSmall } from '../../../../UI/atoms/fonts/Headline';
import IconButton from '../../../../UI/molecules/icon-button/IconButton';
import { Permission } from '../../../../../models/user.models';
import AccessRequired from '../../components/AccessRequired';
import { setIsDeleteCompanyDialogOpen } from '../../../../dialogs/delete-company-dialog/deleteCompanyDialog.duck';
import { canUserUpdateCompanySettings } from "../../../../../utils/AccessControl";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { reset, selectEditCompanyState } from "./editCompany.duck";
import { ConfigurationSection } from "../../StyleComponents";
import { DomainBasedSignUpSetting } from "../../../../UI/organisms/settings/DomainBasedSignUpSetting";
import { LineManagementSettings } from "../../../../UI/organisms/settings/LineManagementSettings";
import { SevenDayWorkingWeekSettings } from "../../../../UI/organisms/settings/SevenDayWorkingWeekSettings";
import { TenantBasedSignUpSetting } from "../../../../UI/organisms/settings/TenantBasedSignUpSetting";
import { WeeklyNotesSetting } from "../../../../UI/organisms/settings/WeeklyNotesSetting";
import { useTranslation } from "react-i18next";
import {
  DisableUpdatingPreviousWhereaboutsSetting
} from "../../../../UI/organisms/settings/DisableUpdatingPreviousWhereaboutsSetting";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { EditingFutureWhereaboutsSettings } from "../../../../UI/organisms/settings/EditingFutureWhereaboutsSettings";
import { CompanyNameSetting } from "../../../../UI/organisms/settings/CompanyNameSetting";
import { WhereaboutsIconPackSetting } from "../../../../UI/organisms/settings/WhereaboutsIconPackSetting";
import { AzureScimIntegrationSettingMessage } from "../../../../UI/organisms/settings/AzureScimIntegrationMessage";
import { BuildVersion } from "../../../../UI/organisms/settings/BuildVersion";
import { VisitorBookingSettings } from "../../../../UI/organisms/settings/VisitorBookingSettings";
import { PrivateGroupsSetting } from "../../../../UI/organisms/settings/PrivateGroupsSetting";
import { RestrictTeamManagement } from "../../../../UI/organisms/settings/RestrictTeamManagement";
import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { GlobalEmailRemindersSetting } from "../../../../UI/organisms/settings/GlobalEmailRemindersSetting";
import { ShowBirthdaysOnWhereaboutsSetting } from "../../../../UI/organisms/settings/ShowBirthdaysOnWhereaboutsSetting";

export default function CompanySettingsPage() {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const state = useSelector(selectEditCompanyState);
  const editable = user?.role === Permission.CompanyAdmin;
  const isEditable = useMemo(() => canUserUpdateCompanySettings(user), [user]);
  const {t} = useTranslation();

  const company = user?.companyEntity;

  const openDeleteDialog = () => dispatch(setIsDeleteCompanyDialogOpen({isOpen: true, company: state.companyName}));

  useEffect(() => {
    dispatch(reset())
    return () => dispatch(reset());
  }, [dispatch]);

  if (!company) {
    return null // loading
  }

  return (
    <Container>

      {isEditable && <>
        <Section>
          <HeadlineLarge className="management__title">{t('company-settings-page.company-settings')}</HeadlineLarge>
          <HeadlineSmall style={{marginBottom: 16}}>{t('company-settings-page.company-preferences')}</HeadlineSmall>

          <AccessRequired visible={!editable} companyAdminsRequired={true}/>

          <ConfigurationSection>
            <CompanyNameSetting editable={editable} />
          </ConfigurationSection>

          <WhereaboutsIconPackSetting />
          <EditingFutureWhereaboutsSettings />
          <SevenDayWorkingWeekSettings />
          <LineManagementSettings />
          <WeeklyNotesSetting />
          <DisableUpdatingPreviousWhereaboutsSetting />
          <GlobalEmailRemindersSetting />
        </Section>

        <Section>
          <HeadlineSmall style={{marginBottom: 16}}>{t('company-settings-page.signup-and-register')}</HeadlineSmall>
          <DomainBasedSignUpSetting settingsView={true} />
          <TenantBasedSignUpSetting />
        </Section>

        <Section>
          <HeadlineSmall style={{marginBottom: 16}}>{t('company-settings-page.azure-and-active-dir-settings')}</HeadlineSmall>
          <AzureScimIntegrationSettingMessage style={{}} />
        </Section>

        <Section>
          <HeadlineSmall style={{marginBottom: 16}}>{t('company-settings-page.other')}</HeadlineSmall>
          <VisitorBookingSettings />
          <PrivateGroupsSetting />
          <RestrictTeamManagement />
          <ShowBirthdaysOnWhereaboutsSetting />
          <BuildVersion />
        </Section>











        <div className="management__row management__loneControl" style={{marginBottom: 32}}>
          <IconButton className="editCompanyPage__deleteCompanyBtn"
                      disabled={!editable}
                      icon={IconTypes.Bin}
                      text="button.delete-account"
                      color="darkGrey"
                      onClick={() => openDeleteDialog()}/>
        </div>
      </>}

    </Container>
  );
}

const Container = styled.div`
  margin: 16px auto 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 684px;
`

const Section = styled.div`
  width: 100%;
  background-color: white;
  max-width: 684px;
  padding: 0;
  margin-bottom: 64px;
  @media (${tablet}) {
    margin-bottom: 32px;
    border-radius: 24px;
    padding: 48px 64px;
  }
`

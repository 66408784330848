import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectAllDepartments } from "./data/departments.duck";
import Layout from "../../UI/organisms/layout/Layout";
import { Column, Row } from "../../UI/atoms/StructuralLayout";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { TeamContentCardList } from "../company-movements/components/ViewComponents";
import { TeamContentCard } from "../../UI/molecules/TeamContentCard";
import { CreateTagContentCard } from "../../UI/molecules/CreateTagContentCard";
import { CompanyTeamViewSidebar } from "../../UI/molecules/CompanyTeamViewSidebar";
import { Department } from "./data/departments.models";
import { useTranslation } from "react-i18next";
import { WithCurrentUser } from "../../../hooks/WithCurrentUser";
import { isCompanyAdminUser } from "../../../utils/AccessControl";
import { DialogIdentifiers, openDialogWithPayload } from "../../../store/ducks/dialog.duck";

export function DepartmentsListPage(props: Props) {
  const {t} = useTranslation();
  const departments = useSelector(selectAllDepartments);
  const currentUser = WithCurrentUser();
  const showEditIcon = isCompanyAdminUser(currentUser);
  const dispatch = useDispatch();

  const onEditClick = (department: Department) => {
    dispatch(openDialogWithPayload({activeDialog: DialogIdentifiers.EditDepartmentDialog, payload: {department: department}}));
  }

  const onDepartmentChecked = (department: Department, state: boolean) => {
  }

  return (
    <Layout>
      <Row>
        <Column className="companyMovements__movements">
          <HeadlineMedium style={{marginBottom: 24}}>{t('company-view-page.departments')}</HeadlineMedium>

          <TeamContentCardList>
            {departments.map((department: Department, key: number) =>
              <TeamContentCard key={key} label={department.name}
                               link={`/whereabouts/company/departments/${department.id}`}
                               showEditIcon={showEditIcon}
                               memberCount={department.count}
                               onEditClick={() => onEditClick(department)}
                               disabled={!!department.restricted}
                               checkboxEnabled={showEditIcon}
                               isChecked={false}
                               onCheckboxChecked={(state: boolean) => onDepartmentChecked(department, state)} />)}
            <CreateTagContentCard label={'company-view-page.create-new-department'}
                                  dialogToOpen={DialogIdentifiers.EditDepartmentDialog}
                                  buttonLabel={"button.create-new-department"} />
          </TeamContentCardList>
        </Column>
        <CompanyTeamViewSidebar />
      </Row>
    </Layout>
  )
}

interface Props {
}

import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { BasicUserInfo } from '../../../models/user.models';
import { findUserRequest } from '../../../services/UserService';
import { PrimaryButton } from '../../UI/atoms/buttons/PrimaryButton';
import { Checkbox } from '../../UI/atoms/Checkbox';
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import TextField from '../../UI/atoms/TextField';
import Dialog from '../../UI/molecules/Dialog';
import { UserAvatarAndName } from '../../UI/molecules/UserAvatarAndName';
import DialogHeader from '../DialogHeader';
import { naturalSort } from "../../../utils/ArrayUtils";
import { useTranslation } from "react-i18next";

export enum UserSelectType {
  multiSelect, singleSelect
}

interface Props {
  onClose: any;
  title: string;
  description: string;
  type: UserSelectType;
  selectedUsers?: BasicUserInfo[];
  onUserSelect: any;
}

export default function UserSearchDialog(props: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<BasicUserInfo[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<BasicUserInfo[]>(props.selectedUsers ?? []);
  const {t} = useTranslation();

  useEffect(() => {
    const id = setTimeout(() => {
      findUserRequest(searchQuery)
        .then((result: BasicUserInfo[]) => naturalSort(result, 'fullName'))
        .then((result: BasicUserInfo[]) => setUsers(result));
    }, 250);

    return () => {
      clearTimeout(id);
    }
  }, [searchQuery]);

  useEffect(() => {
    setSelectedUsers(props.selectedUsers ?? []);
  }, [props])


  const close = () => props.onClose();
  const onChange = (val: string) => setSearchQuery(val);

  const onUserSelect = (selectedUser: BasicUserInfo) => {
    if (props.onUserSelect) {
      props.onUserSelect(selectedUser);
    }
    if (props.type === UserSelectType.singleSelect) {
      props.onClose(selectedUser);
    } else {
      if (selectedUsers.includes(selectedUser)) {
        setSelectedUsers(selectedUsers.filter(su => su.userId !== selectedUser.userId));
      } else {
        setSelectedUsers([...selectedUsers, selectedUser]);
      }
    }
  }

  const done = () => {
    props.onClose(props.type === UserSelectType.multiSelect ? selectedUsers : undefined);
  }

  return (
    <Dialog isOpen={true} onClose={close}>
      <DialogHeader title={props.title} />
      {props.type === UserSelectType.multiSelect && <BodyRegular style={{marginBottom: 6}}>{t(props.description, { userCount: selectedUsers.length })}</BodyRegular>}
      <TextField value={searchQuery} onChange={onChange} />
      <SearchResults className={"searchDialog__results"}>
        {users.map((user: BasicUserInfo, key: number) => (
          <UserInfoRow key={key} className={'searchDialog__result'}>
            <UserAvatarAndName firstName={user.firstName} lastName={user.lastName} />
            <Checkbox value={!!selectedUsers.find(su => su.userId === user.userId)} onChange={() => onUserSelect(user)} />
          </UserInfoRow>
        ))}
      </SearchResults>
      <PrimaryButton click={done} text="button.done" size="large" fullWidth={true} dataTest={"user-search-dialog-done"} />
    </Dialog>
  )
}

const SearchResults = styled.div`
  margin-bottom: 32px;
  height: 250px;
  overflow-y: auto;
`

const UserInfoRow = styled.div<any>`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  .userAvatarAndName {
    cursor: pointer;
  }
`

import React, { useMemo } from 'react';
import { BirthdayIcon } from "../../../UI/atoms/BirthdayIcon";
import moment, { Moment } from "moment";
import { Birthday } from "../../../../models/user.models";
import { DAY_WIDTH } from "../WallPlannerStructuralComponents";
import styled from "styled-components/macro";

export function FloatingBirthdayIcon(props: Props) {
  const {monthStartDate, birthday, weeksInMonth} = props;

  const applyOffset = useMemo(() => {
    return !window.location.href.includes('/holidays/leave-planner');
  }, [])

  const daysBetweenStartOfMonthAndBirthday = useMemo(() => {
    let birthdayDate = moment(`${monthStartDate.year()}-${birthday.month}-${birthday.day}`);
    if (birthday.month === 1 && monthStartDate.month() === 11 && monthStartDate.date() > 5) {
      // Edge case when birthday is Jan but start of calendar month is December
      // Birthday Jan but Dec start of Cal
      birthdayDate = birthdayDate.add(1, 'years');
    }
    return birthdayDate.diff(monthStartDate, 'days');
  }, [birthday, monthStartDate]);

  const positionLeft = daysBetweenStartOfMonthAndBirthday * DAY_WIDTH;

  if (daysBetweenStartOfMonthAndBirthday > weeksInMonth * 7) {
    return null;
  }

  return (
    <BirthdayIconWrapper>
      <BirthdayIcon style={{left: positionLeft + (applyOffset ? 8 : -2), width: 36, top: applyOffset ? -12 : 0}} />
    </BirthdayIconWrapper>
  )
}

interface Props {
  monthStartDate: Moment;
  weeksInMonth: number;
  birthday: Birthday;
}

const BirthdayIconWrapper = styled.div`
  position: absolute;
  margin-left: 230px;
`

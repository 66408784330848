import React, { useMemo } from 'react';
import { GoBackButton } from '../../../../UI/atoms/buttons/GoBackButton';
import { HeadlineLarge } from "../../../../UI/atoms/fonts/Headline";
import { ReportingContentCardFull } from "./components/ReportingContentCardFull";
import { ReportingContentCardSmall } from "./components/ReportingContentCardSmall";
import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import Colours from "../../../../UI/atoms/Colours";
import {
  canUserViewOfficeReports,
  canUserViewReportingPage,
  canUserViewTeamReports
} from "../../../../../utils/AccessControl";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import AccessRequired from "../../components/AccessRequired";
import { useTranslation } from "react-i18next";

export default function ReportingPageV2() {
  const user = useSelector(selectCurrentUser);
  const canViewPage = useMemo(() => canUserViewReportingPage(user), [user]);
  const canViewOfficeReport = useMemo(() => canUserViewOfficeReports(user), [user]);
  const canViewTeamReport = useMemo(() => canUserViewTeamReports(user), [user]);
  const {t} = useTranslation();

  document.body.style.backgroundColor = Colours.veryLightGrey;
  return (
    <div className="management" style={{paddingTop: 24, backgroundColor: Colours.veryLightGrey}}>
      <GoBackButton url="/manage"/>
      <HeadlineLarge className="management__title">{t('reports-page.reporting')}</HeadlineLarge>
      {canViewPage ? <>
        <ReportingContentCardFull imageUrl={"/assets/images/reporting-office.svg"}
                                  title={"reports-page.office-usage-title"}
                                  isVisible={canViewOfficeReport}
                                  description={"reports-page.office-usage-description"}
                                  link={"/manage/reporting/office"} />
        <ContentCardHolder>
          <ReportingContentCardSmall imageUrl={"/assets/icons/standard/House.svg"}
                                     title={"reports-page.wfh-title"}
                                     isVisible={canViewTeamReport}
                                     description={"reports-page.wfh-description"}
                                     link={"/manage/reporting"}
                                     disabled={true}  />

          <ReportingContentCardSmall imageUrl={"/assets/icons/standard/Offsite.svg"}
                                     title={"reports-page.whereabouts-title"}
                                     isVisible={canViewTeamReport}
                                     description={"reports-page.whereabouts-description"}
                                     link={"/manage/reporting/whereabouts"} />

          <ReportingContentCardSmall imageUrl={"/assets/icons/font/Users.svg"}
                                     title={"reports-page.unknown-title"}
                                     isVisible={canViewTeamReport}
                                     description={"reports-page.unknown-description"}
                                     link={"/manage/reporting/unknown-whereabouts"}
                                     disabled={false} />
        </ContentCardHolder>
        <ContentCardHolder>

          <ReportingContentCardSmall imageUrl={"/assets/icons/first-aider.svg"}
                                     title={"reports-page.key-responsib.title"}
                                     isVisible={canViewTeamReport}
                                     description={"reports-page.key-responsib.description"}
                                     link={"/manage/reporting/key-responsibilities"} />

          {user?.companyEntity.enableHolidayBooking && <ReportingContentCardSmall imageUrl={"/assets/icons/standard/Holiday.svg"}
                                                                                  title={"reports-page.holiday-usage-title"}
                                                                                  isVisible={canViewTeamReport}
                                                                                  description={"reports-page.holiday-usage-title"}
                                                                                  link={"/manage/reporting/holidays"} />}

          {user?.companyEntity.officeAttendanceConfirmationEnabled && <ReportingContentCardSmall imageUrl={"/assets/icons/standard/Office.svg"}
                                                                                                 title={"reports-page.checkin-title"}
                                                                                                 isVisible={canViewOfficeReport}
                                                                                                 description={"reports-page.checkin-description"}
                                                                                                 link={"/manage/reporting/office-checkin"} />}

        </ContentCardHolder>
        <ContentCardHolder>

          <ReportingContentCardSmall imageUrl={"/assets/icons/font/Users.svg"}
                                     title={"reports-page.line-reports-title"}
                                     isVisible={canViewTeamReport}
                                     description={"reports-page.line-reports-description"}
                                     link={"/manage/reporting/line-reports"}
                                     disabled={false} />

        </ContentCardHolder>
      </> : <>
        <AccessRequired visible={!canViewPage} companyAdminsRequired={true} officeAdminsRequired={true} teamAdminsRequired={true} />
      </>}
    </div>
  );
}

const ContentCardHolder = styled.div`
  display: flex;
  flex-direction: column;
  &>* {
    width: 100%
  }
  @media (${tablet}) {
    flex-direction: row;
    &>* {
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import Dialog from "../../../UI/molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import TextField from "../../../UI/atoms/TextField";
import { BasicUserInfo } from "../../../../models/user.models";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { Checkbox } from "../../../UI/atoms/Checkbox";
import { findUserRequest } from "../../../../services/UserService";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { updateDeskOwnershipsForUsers } from "../../../../services/DeskOwnershipService";
import { clearSelectedDeskIds, selectSelectedDeskIds } from "../ducks/manageDesks.duck";
import { successNotification } from "../../../../store/ducks/notification.duck";
import { fetchDesks } from "../../../../store/ducks/deskBooking.duck";
import { selectSelectedOffice } from "../../../../store/ducks/officeView.duck";

export function AssignDesksToIndividualDialog() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<BasicUserInfo[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<BasicUserInfo[]>([]);
  const selectedDeskIds = useSelector(selectSelectedDeskIds);
  const selectedOffice = useSelector(selectSelectedOffice);

  useEffect(() => {
    const id = setTimeout(() => {
      findUserRequest(searchQuery)
        .then((result: BasicUserInfo[]) => naturalSort(result, 'fullName'))
        .then((result: BasicUserInfo[]) => setUsers(result));
    }, 250);

    return () => {
      clearTimeout(id);
    }
  }, [searchQuery]);

  const close = () => {
    dispatch(closeDialog())
  };

  const updateTagClick = async () => {
    setLoading(true);
    try {
      const userIds = selectedUsers.map(u => u.userId);
      await updateDeskOwnershipsForUsers(selectedDeskIds, userIds);
      dispatch(clearSelectedDeskIds());
      if (selectedOffice) {
        dispatch(fetchDesks(selectedOffice.id));
      }
      dispatch(successNotification('Desks assigned to individuals'))
      close();
    } finally {
      setLoading(false);
    }
  };

  const onChange = (val: string) => setSearchQuery(val);

  const onUserSelect = (selectedUser: BasicUserInfo) => {
    if (selectedUsers.includes(selectedUser)) {
      setSelectedUsers(selectedUsers.filter(su => su.userId !== selectedUser.userId));
    } else {
      setSelectedUsers([...selectedUsers, selectedUser]);
    }
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-desks-sidebar.assign-desks-to-individual" />

      <TextField value={searchQuery} onChange={onChange} disabled={loading} />

      <SearchResults className={"searchDialog__results"}>
        {users.map((user: BasicUserInfo, key: number) => (
          <UserInfoRow key={key} className={'searchDialog__result'}>
            <UserAvatarAndName firstName={user.firstName} lastName={user.lastName} />
            <Checkbox value={!!selectedUsers.find(su => su.userId === user.userId)} onChange={() => onUserSelect(user)} disabled={loading} />
          </UserInfoRow>
        ))}
      </SearchResults>

      <PrimaryButton disabled={loading}
                     fullWidth={true}
                     click={updateTagClick}
                     style={{marginTop: 'auto'}}
                     text="button.save-changes" />
    </DialogWrapper>
  )
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 410px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 500px;
    min-height: 500px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
`

const SearchResults = styled.div`
  margin-bottom: 32px;
  height: 250px;
  overflow-y: auto;
`

const UserInfoRow = styled.div<any>`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  .userAvatarAndName {
    cursor: pointer;
  }
`

import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import moment, { Moment } from 'moment';
import { emptyWhereaboutsDay, Period, UsersWhereaboutsDay, WhereaboutsDayV2 } from '../../models/movements.models';
import { WhereaboutsOption } from "../../services/WhereaboutOptions";
import { requestUsersWeeklyMovements, updateWhereaboutsDay } from "../../services/Movements";
import {
  updateCurrentUsersWhereabouts,
  updateTeamMembersWhereabouts
} from "../../components/pages/team-movements/models/team-movements.duck";
import { DATE_FORMAT, getListOfDaysBetweenInclusiveToExclusive } from "../../utils/DateUtils";
import { loadTeamMovements } from "./companyMovements.duck";
import { setCalendarDate } from "./dashboard.duck";
import { DialogIdentifiers, openDialog, openDialogWithPayload } from "./dialog.duck";
import { failureNotification, warningNotification } from "./notification.duck";
import { WhereaboutsBookingErrorType } from "../../services/DeskBookingService";
import { WhereaboutsResponseError } from "../../models/api.models";

export enum OfficeWhereaboutsView {
  Office, CarPark,
}

export interface EditMovementsState {
  focusedUserId?: string;
  activeDay?: Moment;
  activePeriod: Period;
  userRequiresParking: boolean;
  editingWhereabouts: UsersWhereaboutsDay[];
}

export const initialEditMovementsState: EditMovementsState = {
  activeDay: moment(),
  activePeriod: Period.AllDay,
  userRequiresParking: false,
  editingWhereabouts: []
}

export const startEditingWhereabouts: any = createAsyncThunk('editMovements/startEditingWhereabouts',
  async (params: {userId: string, date: Moment}, thunkAPI) => {
    const memberWhereabouts = await requestUsersWeeklyMovements(params.date, params.userId);
    const appState = (thunkAPI.getState() as AppState);

    const currentUser = appState.auth.currentUser;

    const weekCommencing = params.date.clone().startOf('isoWeek');

    const sevenDayWeek = appState.auth.currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled;
    const days = getListOfDaysBetweenInclusiveToExclusive(weekCommencing, weekCommencing.clone().add(sevenDayWeek ? 7 : 5, 'days'))

    const editingWhereabouts: UsersWhereaboutsDay[] = [];
    days.forEach(date => {
      const whereaboutsOnDate = memberWhereabouts.whereabouts.find(mw => mw.date === date.format(DATE_FORMAT));
      if (whereaboutsOnDate) {
        editingWhereabouts.push(whereaboutsOnDate);
      } else {
        editingWhereabouts.push(emptyWhereaboutsDay(date.format(DATE_FORMAT), params.userId));
      }
    });

    return {
      userId: params.userId,
      date: params.date,
      period: Period.AllDay,
      editingWhereabouts: editingWhereabouts,
      userRequiresParking: currentUser?.id === params.userId && currentUser?.carParkingEnabled,
    }
  }
);

function handleWhereaboutsError(error: WhereaboutsResponseError, updatedWhereabouts: WhereaboutsDayV2, dispatch: any) {
  switch (error.type) {
    case WhereaboutsBookingErrorType.OUT_OF_BOOKING_RANGE:
      dispatch(openDialogWithPayload({
        payload: { weekCommencing: moment(updatedWhereabouts.date).format(DATE_FORMAT), officeId: updatedWhereabouts.amOfficeId || updatedWhereabouts.pmOfficeId },
        activeDialog: DialogIdentifiers.UnableToBookDesksErrorDialog
      }))
      break;
    case WhereaboutsBookingErrorType.CANNOT_BOOK_OVER_APPROVED_HOLIDAY:
      dispatch(openDialog(DialogIdentifiers.CannotEditApprovedHoliday));
      break;
    case WhereaboutsBookingErrorType.NO_PARKING_SPACE:
      dispatch(warningNotification('', 'Parking unavailable on Tuesday'));
      break;
    case WhereaboutsBookingErrorType.CANNOT_EDIT_PAST_WHEREABOUTS:
      dispatch(warningNotification('Cannot edit past whereabouts for that user', ''));
      break;
    case WhereaboutsBookingErrorType.DESK_NOT_ACTIVE:
      dispatch(warningNotification('That desk is not active', ''));
      break;
    default: dispatch(failureNotification('Contact support if the error persists', 'Failed to save'));
      break;
  }
}

export const endEditingWhereabouts: any = createAsyncThunk('editMovements/endEditingWhereabouts',
  async (params: void, thunkAPI) => {
    const appState = (thunkAPI.getState() as AppState);
    const date = appState.editMovements.activeDay;

    function hasSavingWhereabouts() {
      const editingWhereabouts = (thunkAPI.getState() as AppState).editMovements.editingWhereabouts;
      const whereaboutsStillSaving = editingWhereabouts.filter(w => !!w.isWhereaboutsSaving).length;
      return whereaboutsStillSaving > 0;
    }

    async function hasSavingWhereaboutsCheck(counter: number) {
      if (counter < 5 && hasSavingWhereabouts()) {
        await new Promise((resolve: any) => setTimeout(() => resolve(), 250));
        await hasSavingWhereaboutsCheck(counter++);
      }
    }

    await hasSavingWhereaboutsCheck(0);
    await new Promise((resolve: any) => setTimeout(() => resolve(), 250));

    appState.teamMovements.teamWhereabouts.filter(t => t.visible).forEach(t => {
      thunkAPI.dispatch(loadTeamMovements(t));
    })
    thunkAPI.dispatch(setCalendarDate(date?.clone().startOf('isoWeek')));
  }
);

export const updateMovement: any = createAsyncThunk(
  'editMovements/updateMovement',
  async (params: {selectedOption: WhereaboutsOption, locationId: number, activeDay?: string, activeUserId?: string, deskId?: number, skip?: boolean, stickOnDay?: boolean, parkingSpaceId?: number}, thunkAPI) => {
    const editMovementsState = (thunkAPI.getState() as AppState).editMovements;
    const activeDay = params?.activeDay ?? editMovementsState.activeDay?.format(DATE_FORMAT);
    const activePeriod = editMovementsState.activePeriod;

    let updatedWhereabouts: any = undefined;
    let originalWhereabouts: any = undefined;
    editMovementsState.editingWhereabouts.forEach(whereaboutsDay => {
      if (whereaboutsDay.date === activeDay) {
        originalWhereabouts = {...whereaboutsDay};
        updatedWhereabouts = {...whereaboutsDay};
        if (activePeriod === Period.AM || activePeriod === Period.AllDay) {
          updatedWhereabouts = {
            ...updatedWhereabouts,
            amStatusId: params.selectedOption.id,
            amStatus: params.selectedOption.status,
            amOfficeId: params?.locationId ?? 0,
            amDeskId: params?.deskId ?? 0,
            amParkingSpaceId: params?.parkingSpaceId ?? 0,
            amDeskLabel: undefined,
            amGeneralParking: editMovementsState.userRequiresParking && !params?.parkingSpaceId,
            isWhereaboutsSaving: false,
            whereaboutsUpdateFailed: false,
          }
        }
        if (activePeriod === Period.PM || activePeriod === Period.AllDay) {
          updatedWhereabouts = {
            ...updatedWhereabouts,
            pmStatusId: params.selectedOption.id,
            pmStatus: params.selectedOption.status,
            pmOfficeId: params?.locationId ?? 0,
            pmDeskId: params?.deskId ?? 0,
            pmParkingSpaceId: params?.parkingSpaceId ?? 0,
            pmDeskLabel: undefined,
            pmGeneralParking: editMovementsState.userRequiresParking && !params?.parkingSpaceId,
            isWhereaboutsSaving: false,
            whereaboutsUpdateFailed: false,
          }
        }
      }
    })

    const appState = (thunkAPI.getState() as AppState);


    if (!params.stickOnDay) {
      if (activePeriod === Period.AllDay || activePeriod === Period.PM) {
        const currentDay = moment(activeDay).day();
        const sevenDayWhereaboutsWeekEnabled = appState.auth.currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled
        if (sevenDayWhereaboutsWeekEnabled) {
          if (currentDay > 0 && currentDay < 7) {
            const updatedActiveDay = moment(activeDay).add(1, 'days');
            thunkAPI.dispatch(setActiveDay(updatedActiveDay));
          } else {
            thunkAPI.dispatch(endEditingWhereabouts());
          }
        } else {
          if (currentDay < 5) {
            const updatedActiveDay = moment(activeDay).add(1, 'days');
            thunkAPI.dispatch(setActiveDay(updatedActiveDay));
          } else {
            thunkAPI.dispatch(endEditingWhereabouts());
          }
        }
        if (activePeriod === Period.PM) {
          thunkAPI.dispatch(setActivePeriod(Period.AllDay));
        }
      } else {
        thunkAPI.dispatch(setActivePeriod(Period.PM));
      }
    }

    // Update server with whereabouts
    if (updatedWhereabouts && originalWhereabouts) {
      await new Promise((resolve: any) => setTimeout(() => resolve(), 80));
      const result = await updateWhereaboutsDay(updatedWhereabouts);

      if (result.errors.length > 0) {
        const error = result.errors[0];
        handleWhereaboutsError(error, updatedWhereabouts, thunkAPI.dispatch);
        updatedWhereabouts = {...originalWhereabouts, isWhereaboutsSaving: false, whereaboutsUpdateFailed: true};

      } else {
        // Update whereabouts datastore
        const currentUser = appState.auth.currentUser;
        if (editMovementsState.focusedUserId === currentUser?.id) {
          thunkAPI.dispatch(updateCurrentUsersWhereabouts({whereaboutsToReplace: result.whereaboutsDay}));
        } else {
          thunkAPI.dispatch(updateTeamMembersWhereabouts({whereaboutsToReplace: result.whereaboutsDay}));
        }
      }
    }

    return {
      activeDateString: activeDay,
      updatedWhereabouts: updatedWhereabouts,
    }
  }
)

export const openRepeatWhereaboutsDialog: any = createAsyncThunk(
  'editMovements/openRepeatWhereaboutsDialog',
  async (params: any, thunkAPI) => {
    const appState = (thunkAPI.getState() as AppState);
    thunkAPI.dispatch(openDialogWithPayload({
      payload: {
        whereabouts:  [...appState.editMovements.editingWhereabouts],
        userId: appState.editMovements.focusedUserId,
      },
      activeDialog: DialogIdentifiers.RepeatScheduleDialog,
    }))
  }
)

const editMovementsSlice = createSlice({
  name: 'editMovements',
  initialState: initialEditMovementsState,
  reducers: {
    resetEditMovements: () => ({...initialEditMovementsState}),
    setActiveDay: (state, action) => ({...state, activeDay: action.payload}),
    setActivePeriod: (state, action) => ({...state, activePeriod: action.payload}),
    setUserRequiresParking: (state, action) => ({...state, userRequiresParking: action.payload}),
  },
  extraReducers: {
    [updateMovement.pending]: (state, action) => {
      const date = action.meta.arg.activeDay || state.activeDay?.format(DATE_FORMAT);
      const whereabouts = state.editingWhereabouts.map(w => w.date === date ? {...w, isWhereaboutsSaving: true} : w);
      return {
        ...state,
        editingWhereabouts: whereabouts,
      }
    },
    [updateMovement.fulfilled]: (state, action) => {
      const date = action.payload.activeDateString;
      const whereabouts = state.editingWhereabouts.map(w => (w.date === date) ? action.payload.updatedWhereabouts : w);
      return {
        ...state,
        editingWhereabouts: whereabouts,
      }
    },

    [startEditingWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      focusedUserId: action.payload.userId,
      activeDay: action.payload.date,
      activePeriod: action.payload.period,
      editingWhereabouts: action.payload.editingWhereabouts,
      userRequiresParking: action.payload.userRequiresParking,
    }),

    [endEditingWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      focusedUserId: undefined,
      editingWhereabouts: [],
    }),
  }
})

export default editMovementsSlice.reducer;
export const {
  resetEditMovements,
  setActiveDay,
  setActivePeriod,
  setUserRequiresParking,
} = editMovementsSlice.actions;

// Selectors
export const selectActiveDay = (state: AppState) => state.editMovements.activeDay;
export const selectActivePeriod = (state: AppState) => state.editMovements.activePeriod;
export const selectFocusedUserId = (state: AppState) => state.editMovements.focusedUserId;

export const selectUserRequiredParking = (state: AppState) => state.editMovements.userRequiresParking;

export const selectEditingWhereabouts = (state: AppState) => state.editMovements.editingWhereabouts;

import React from 'react';
import { CompanyViewContentCardWrapper } from "./TeamContentCard";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../atoms/fonts/Headline";
import { OutlineButton } from "../atoms/buttons/OutlineButton";
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../store/ducks/dialog.duck";
import { useTranslation } from "react-i18next";

export function CreateTagContentCard(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const onClick = () => {
    dispatch(openDialogWithPayload({ payload: { }, activeDialog: props.dialogToOpen }));
  }
  return (
    <Wrapper>
      <HeadlineSmall style={{marginBottom: 16}}>{t(props.label)}</HeadlineSmall>
      <OutlineButton size={"medium"}
                     text={props.buttonLabel}
                     click={onClick} />
    </Wrapper>
  )
}

interface Props {
  label: string;
  buttonLabel: string;
  dialogToOpen: DialogIdentifiers;
}

const Wrapper = styled<any>(CompanyViewContentCardWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-color: transparent !important;
  cursor: default;
  box-shadow: 0 7px 11px 2px rgba(142, 149, 163, 0.15);
`

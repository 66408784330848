import React, { useEffect, useMemo } from 'react';
import { Column } from "../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { MountedContentCard } from "../../UI/atoms/MountedContentCard";
import { useParams } from "react-router";
import {
  loadUsersHolidays,
  selectHolidayUserInfo,
  selectUsersBookedHolidays
} from "../../../store/ducks/holidays-v2.duck";
import Layout from '../../UI/organisms/layout/Layout';
import { DATE_FORMAT, PRETTY_SHORT_DATE_FORMAT } from "../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { BodyRegular, BodyVerySmall } from "../../UI/atoms/fonts/Body";
import { UserInfoBox } from "../../UI/molecules/UserInfoBox";
import { selectConfig } from "../../../store/ducks/config.duck";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import moment from "moment";
import { Pill } from "../../UI/atoms/Pill";
import Colours from "../../UI/atoms/Colours";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import IconButton from "../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../UI/atoms/icon/Icon";

export function HolidayCreatedSuccessfullyPage() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const params = useParams<any>();
  const userIdFromParams = params.user;
  const holidayUserInfo = useSelector(selectHolidayUserInfo);
  const holidayBookings = useSelector(selectUsersBookedHolidays);
  const config = useSelector(selectConfig);

  const fromDate = moment(params.from);
  const toDate = moment(params.to);

  const daysBookedOff = useMemo(() => {
    return holidayBookings.filter(hb => hb.date >= fromDate.format(DATE_FORMAT) && hb.date <= toDate.format(DATE_FORMAT)).length
  }, [holidayBookings, fromDate, toDate]);

  useEffect(() => {
    if (userIdFromParams) {
      dispatch(loadUsersHolidays({userId: userIdFromParams}));
    }
  }, [dispatch, userIdFromParams]);


  return (
    <Layout>
      <MountedContentCardWrapper centre={true}>
        <GoBackButton/>
        <Column style={{position: 'relative', marginBottom: 8}}>
          <HeadlineLarge style={{marginBottom: 12, marginLeft: 'auto', marginRight: 'auto'}}>{t('holidays.booked')}</HeadlineLarge>
          <BodyRegular style={{marginBottom: 24, marginLeft: 'auto', marginRight: 'auto'}}>{t('holidays.holiday-year-span', {from: config.getHolidayYearStartDate()?.format(PRETTY_SHORT_DATE_FORMAT), to: config.getHolidayYearEndDate()?.format(PRETTY_SHORT_DATE_FORMAT)})}</BodyRegular>

          <UserInfoBox firstName={holidayUserInfo.firstName}
                       lastName={holidayUserInfo.lastName}
                       teamName={holidayUserInfo.teamName}
                       userId={holidayUserInfo.userId}
                       style={{marginBottom: 24, maxWidth: '100%', width: '100%'}}>

            <IconButton link={`/holidays/${holidayUserInfo.userId}/edit/${fromDate.format(DATE_FORMAT)}`}
                        style={{position: 'absolute', top: 16, right: 16}}
                        icon={IconTypes.Edit} />

            <BodyRegular style={{marginTop: 18, marginBottom: 12}}>{`${fromDate.format('DD MMM YYYY')} - ${toDate.format('DD MMM YYYY')}`}</BodyRegular>

            <Pill style={{marginBottom: 24}} backgroundColour={Colours.blue02} colour={Colours.blue}>
              <BodyVerySmall weight={600}>{daysBookedOff} {t('approvals.days')}</BodyVerySmall>
            </Pill>

            <OutlineButton text={'holidays.add-more-leave'} fullWidth={true}
                           link={`/holidays/${holidayUserInfo.userId}/add`} />
          </UserInfoBox>
        </Column>



        <PrimaryButton text={'button.done-for-now'}
                       fullWidth={true}
                       link={'/holidays'}/>

      </MountedContentCardWrapper>
    </Layout>
  )
}

const MountedContentCardWrapper = styled<any>(MountedContentCard)`
  margin-top: 40px;
  max-width: 680px;
  
  .linkButton {
    width: 100%;
  }
  
`


import React, { useMemo } from 'react';
import { TeamEntity, TeamWithCount } from '../../../../models/team.models';
import './team-drop-down-list.scss';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import Colours from "../../atoms/Colours";
import { BodyRegular, BodyVerySmall } from "../../atoms/fonts/Body";
import { Tag } from "../../../../models/tags.models";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { DROP_DOWN_STYLES, groupStyles } from "../drop-down-list/DropDownStyles";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { Department } from "../../../pages/departments/data/departments.models";

const animatedComponents = makeAnimated();

export enum MenuOptionType {
  Team, WorkGroup, OfficeTitle, Office, Blank, LineReports, Misc, Department
}

export interface TeamDropDownItem {
  value?: TeamWithCount;
  label: string;
  type: MenuOptionType;
}

export interface WorkGroupDropDownItem {
  value?: Tag;
  label: string;
  type: MenuOptionType;
}

export interface OfficeDropDownItem {
  value?: OfficeEntity;
  label: string;
  type: MenuOptionType;
}

export interface DepartmentDropDownItem {
  value?: Department;
  label: string;
  type: MenuOptionType;
}

export interface GeneralDropDownItem {
  value?: string;
  label: string;
  type: MenuOptionType;
}

export const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <BodyRegular weight={600} colour={Colours.blue}>{data.label}</BodyRegular>
    <BodyVerySmall weight={400} colour={Colours.blue}>{data.options.length}</BodyVerySmall>
  </div>
);

export function formatOptionLabel(data: any) {
  if (data.type === MenuOptionType.OfficeTitle) {
    return (<div {...data} style={groupStyles}>
      <BodyRegular weight={600} colour={Colours.black}>{data.label}</BodyRegular>
    </div>)
  } else {
    return (<div {...data} style={groupStyles}>
      <BodyRegular weight={400} colour={Colours.black}>{data.label}</BodyRegular>

      {data.value && data.value.memberCount && <BodyVerySmall weight={400}
                                                              className={'memberCount'}
                                                              colour={Colours.black}>{data.value.memberCount}</BodyVerySmall>}

      {data.value && data.value.count && <BodyVerySmall weight={400}
                                                              className={'memberCount'}
                                                              colour={Colours.black}>{data.value.count}</BodyVerySmall>}
    </div>)
  }
}

export interface GroupedOption {
  readonly label: string;
  readonly options: TeamDropDownItem[] | WorkGroupDropDownItem[];
}

export default function TeamMultiSelect(props: TeamMultiSelectProps) {
  const { onSelect, showInactiveUserOption } = props;
  const teams = useMemo(() => props.teams ?? [], [props]);
  const workGroups = useMemo(() => props.workGroups ?? [], [props]);
  const offices = useMemo(() => props.offices ?? [], [props]);
  const departments = useMemo(() => props.departments ?? [], [props]);
  const selectedTeams = useMemo(() => props.selectedTeams ?? [], [props]);
  const selectedWorkGroups = useMemo(() => props.selectedWorkGroups ?? [], [props]);
  const selectedOffices = useMemo(() => props.selectedOffices ?? [], [props]);
  const selectedDepartments = useMemo(() => props.selectedDepartments ?? [], [props]);
  const selectedMiscIds = useMemo(() => props.selectedMisc ?? [], [props]);
  const {t} = useTranslation()

  const dropDownWorkGroups: WorkGroupDropDownItem[] = useMemo(() => workGroups.map(w => ({value: w, label: w.label, type: MenuOptionType.WorkGroup})), [workGroups]);
  const dropDownTeams: TeamDropDownItem[] = useMemo(() => teams.map(t => ({value: t, label: t.name, type: MenuOptionType.Team})), [teams]);
  const dropDownOffices: OfficeDropDownItem[] = useMemo(() => offices.map(t => ({value: t, label: t.label, type: props?.styleOfficesAsTitles ? MenuOptionType.OfficeTitle : MenuOptionType.Office})), [offices, props?.styleOfficesAsTitles]);
  const dropDownDepartments: DepartmentDropDownItem[] = useMemo(() => departments.map(t => ({value: t, label: t.name, type: props?.styleOfficesAsTitles ? MenuOptionType.OfficeTitle : MenuOptionType.Department})), [departments, props?.styleOfficesAsTitles]);
  const dropDownMisc: GeneralDropDownItem[] = useMemo(() => {
    let retVal: any[] = [];
    if (showInactiveUserOption) {
      retVal.push(
        {value: 'INACTIVE_USERS', label: 'Inactive users', type: MenuOptionType.Misc},
        {value: 'NO_TEAM', label: 'Users with no team', type: MenuOptionType.Misc},
      )
    }
    return retVal;
  }, [showInactiveUserOption]);

  const selectedItems = useMemo(() => {
    const selectedTeamIds = selectedTeams.map(st => st.id);
    const selectedWorkGroupIds = selectedWorkGroups.map(st => st.id);
    const selectedOfficeIds = selectedOffices.map(so => so.id);
    const selectedDepartmentIds = selectedDepartments.map(d => d.id);

    return [
      // ...lineReports,
      ...dropDownTeams.filter(dropDownTeam => selectedTeamIds.includes(dropDownTeam?.value?.id ?? '')),
      ...dropDownWorkGroups.filter(dropDownWorkGroups => selectedWorkGroupIds.includes(dropDownWorkGroups?.value?.id ?? 0)),
      ...dropDownOffices.filter(dropDownOffices => selectedOfficeIds.includes(dropDownOffices?.value?.id ?? 0)),
      ...dropDownDepartments.filter(dropDownDepartments => selectedDepartmentIds.includes(dropDownDepartments?.value?.id ?? 0)),
      ...dropDownMisc.filter(dropDownMisc => selectedMiscIds.includes(dropDownMisc?.value ?? '')),
    ]
  }, [dropDownDepartments, dropDownMisc, dropDownOffices, dropDownTeams, dropDownWorkGroups, selectedDepartments, selectedMiscIds, selectedOffices, selectedTeams, selectedWorkGroups]);

  const groupedOptions: GroupedOption[] = useMemo(() => {
    let retVal = [{ label: i18next.t('team-view.teams'), options: dropDownTeams as TeamDropDownItem[], }];
    if (dropDownWorkGroups.length > 0) {
      retVal.push({ label: i18next.t('team-view.workgroup'), options: dropDownWorkGroups as any[], })
    }
    if (dropDownOffices.length > 0) {
      retVal.push({ label: i18next.t('team-view.offices'), options: dropDownOffices as any[], })
    }
    if (dropDownDepartments.length > 0) {
      retVal.push({ label: i18next.t('team-view.departments'), options: dropDownDepartments as any[], })
    }
    if (props?.showInactiveUserOption) {
      retVal.push({ label: i18next.t('team-view.misc'), options: dropDownMisc as any[], })
    }
    return retVal;
  }, [dropDownDepartments, dropDownMisc, dropDownOffices, dropDownTeams, dropDownWorkGroups, props?.showInactiveUserOption]);

  const onItemSelected = (selection: any) => {
    if (Array.isArray(selection)) {
      const teams = selection.filter(s => s.type === MenuOptionType.Team).map(i => i.value);
      const workGroups = selection.filter(s => s.type === MenuOptionType.WorkGroup).map(i => i.value);
      const offices = selection.filter(s => s.type === MenuOptionType.OfficeTitle).map(i => i.value);
      const departments = selection.filter(s => s.type === MenuOptionType.Department).map(i => i.value);
      onSelect(teams, workGroups, offices, departments);
    } else {
      if (selection.type === MenuOptionType.Team) {
        onSelect([selection.value], [], [], []);
      } else if (selection.type === MenuOptionType.WorkGroup) {
        onSelect([], [selection.value], [], []);
      } else if (selection.type === MenuOptionType.Misc) {
        onSelect([], [], [], [], false, [selection.value]);
      } else if (selection.type === MenuOptionType.Department) {
        onSelect([], [], [], [selection.value]);
      } else {
        onSelect([], [], [selection.value], []);
      }
    }
  }

  return (
    <Select
      className={props.multiSelectClassName ?? ''}
      value={selectedItems}
      isDisabled={!!props?.disabled}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      onChange={onItemSelected}
      styles={DROP_DOWN_STYLES}
      placeholder={t('Select...')}
      closeMenuOnSelect={!!props.singleSelect}
      components={{ ...animatedComponents, ClearIndicator:() => null, IndicatorSeparator:() => null }}
      isMulti={!props.singleSelect}
    />
  )
}

export interface TeamMultiSelectProps {
  teams?: TeamWithCount[];
  offices?: OfficeEntity[];
  departments?: Department[];
  workGroups?: Tag[];
  onSelect: (team: TeamEntity[], workGroups: Tag[], offices: OfficeEntity[], department: Department[], showLineReports?: boolean, selectedMisc?: string[]) => any;
  selectedTeams?: TeamEntity[] | TeamWithCount[];
  selectedWorkGroups?: Tag[];
  selectedOffices?: OfficeEntity[];
  selectedDepartments?: Department[];
  selectedMisc?: string[];
  lineReportsSelected?: boolean;
  multiSelectClassName?: string;
  label?: string;
  disabled?: boolean;
  singleSelect?: boolean;
  showInactiveUserOption?: boolean;
  styleOfficesAsTitles?: boolean;
}

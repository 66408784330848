import React from 'react';
import { BodySmall } from '../../atoms/fonts/Body';
import './team-drop-down-list.scss';
import Colours from "../../atoms/Colours";
import { useTranslation } from "react-i18next";
import { Department } from "../../../pages/departments/data/departments.models";

const NO_OPTION = '-- select a team --';

export const ALL_DEPARTMENTS = 'ALL';

export default function DepartmentDropDownList(props: Props) {
  const { onSelect, selectedDepartmentId, className, label } = props;
  const departments = props.departments ?? [];
  const {t} = useTranslation();

  const onItemSelected = (e: any) => {
    if (e.target.value && e.target.value !== NO_OPTION) {
      onSelect(e.target.value)
    }
  }

  return (
    <>
      {!!label && <BodySmall colour={Colours.darkGrey} weight={600} className="teamDropDownList__label">{t(label)}</BodySmall>}
      <select disabled={props.disabled}
              className={`teamDropDownList ${className ?? ''}`.trim()}
              name="Team" value={selectedDepartmentId}
              onChange={onItemSelected}>
        {props.includeAllDepartments ? <option value={ALL_DEPARTMENTS}>All departments</option> : <option>{NO_OPTION}</option>}
        {props.includeNoDepartment && <option value={0}>-- No department --</option>}
        {departments.map((t, k) => <option key={k} value={t.id}>{t.name}</option>)}
      </select>
    </>
  )
}


interface Props {
  departments: Department[],
  onSelect: (departmentId: number) => any,
  selectedDepartmentId?: number,
  className?: string,
  label?: string,
  disabled?: boolean,
  includeAllDepartments?: boolean,
  includeNoDepartment?: boolean,
}

import { UserInfoV2 } from "../ducks/ManageUsers.model";
import { Permission } from "../../../../../../models/user.models";
import { Pill } from "../../../../../UI/atoms/Pill";
import Colours from "../../../../../UI/atoms/Colours";
import { BodySmall, BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import React from "react";

export function UserRolePill(props: Props) {
  const {user} = props
  if (user.role === Permission.CompanyAdmin) {
    return (<Pill style={{width: 150}} colour={Colours.plannerGreen} backgroundColour={Colours.plannerGreen}>
      <BodySmall weight={400}>Company admin</BodySmall>
    </Pill>)
  } else if (user.role === Permission.TeamAdmin) {
    return (<Pill style={{width: 140}} colour={Colours.mildGrey} backgroundColour={Colours.mildGrey}>
      <BodySmall weight={400}>Team Admin</BodySmall>
    </Pill>)
  } else if (user.role === Permission.OfficeAdmin) {
    return (<Pill style={{width: 150}} colour={Colours.mildGrey} backgroundColour={Colours.mildGrey}>
      <BodySmall weight={400}>Office Admin</BodySmall>
    </Pill>)
  } else if (user.role === Permission.DepartmentAdmin) {
    return (<Pill style={{width: 150}} colour={Colours.mildGrey} backgroundColour={Colours.mildGrey}>
      <BodyVerySmall weight={400}>Department Admin</BodyVerySmall>
    </Pill>)
  }
  return null;
}

interface Props {
  user: UserInfoV2;
}

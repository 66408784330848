import React, { useMemo } from 'react';
import { HolidayActivityFill } from "./WallPlannerActivityBlocks";
import { daysBetween } from "../../../../utils/DateUtils";
import moment, { Moment } from "moment";
import { PopupPosition } from "./HolidayInfoAndApprovalPopup";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { CompanyHoliday } from "../../../../services/CompanyHolidayService";
import { CompanyHolidayInfoPopup } from "./CompanyHolidayInfoPopup";
import { Birthday } from "../../../../models/user.models";

export function CompanyHolidayBlock(props: Props) {
  const {activity, firstDateOnCalendar} = props;
  const days = 1;

  const popupPositioning = useMemo(() => {
    const daysDiff = moment(activity.date).diff(firstDateOnCalendar, 'days');
    return daysDiff > 20 ? PopupPosition.Right : PopupPosition.Left;
  }, [activity, firstDateOnCalendar]);

  return (
    <HolidayActivityFill start={daysBetween(activity.date, firstDateOnCalendar)}
                         days={days}
                         fill={Colours.veryLightGrey}
                         outline={'#A6B4B4'}
                         textColour={Colours.black}
                         title={activity.label}>
      <Contents>
        <Icon icon={IconTypes.Flag} />
        {props.children && <>{props.children}</>}
      </Contents>
      <CompanyHolidayInfoPopup companyHoliday={activity}
                               popupPositioning={popupPositioning} />
    </HolidayActivityFill>
  )
}

interface Props {
  activity: CompanyHoliday;
  children?: any;
  firstDateOnCalendar: Moment;
  weeksInMonth: number;
  birthday?: Birthday;
}

const Contents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

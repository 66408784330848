import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import './wallchart-header.scss';
import WeekFlicker from '../../../../../pages/team-movements/components/WeekFlicker';
import { BodyRegular } from '../../../../atoms/fonts/Body';
import { MovementsDayContainer } from "../StructuralComponents";
import styled from "styled-components/macro";
import Colours from "../../../../atoms/Colours";
import { WithDaysInWorkWeek } from "../../../../../../hooks/WithDaysInWorkWeek";
import { desktop1100, desktopMidWide, desktopSmall, fromMobile, largeMobile, } from "../../../../atoms/MediaQueries";

export default function WallChartHeader() {
  const workWeekDays = WithDaysInWorkWeek();
  const todaysDate = moment().startOf('day');
  const daysofWeekRowRef = useRef();
  const dateBarStartYPositionRef = useRef(10000);
  const [fixDateBar, setFixDateBar] = useState(false);
  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    if (daysofWeekRowRef.current) {
      const rect = (daysofWeekRowRef.current as any).getBoundingClientRect();
      dateBarStartYPositionRef.current = rect.y;
    }
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (daysofWeekRowRef.current) {
        const daysOfWeekRow = document.getElementById('daysOfWeek');
        if (daysOfWeekRow) {
          const rowRect = daysOfWeekRow.getBoundingClientRect()
          setOffsetX(rowRect.x);
          setFixDateBar(window.scrollY > dateBarStartYPositionRef.current);
        }
      }
    }
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const color = (day: Moment) => {
    return day.isSame(todaysDate, 'days') ? Colours.blue : day.isSameOrBefore(todaysDate, 'days') ? Colours.mildGrey : Colours.darkGrey;
  }

  const isActive = (day: Moment) => day.isSame(todaysDate, 'days');

  useEffect(() => {
    // setupSticky('daysOfWeek', 'wallchartHeader__daysOfWeekContainer--sticky');
  }, [])

  return (
    <>
      <Container fixDateBar={fixDateBar}>
        <Wrapper>
          <WallChartContent ref={daysofWeekRowRef}
                            offsetX={offsetX}
                            fixDateBar={fixDateBar}
                            data-test="daysOfWeek"
                            id="daysOfWeek"
                            className="wallchartHeader__daysOfWeekContainer">

            <WeekFlicker className="wallchartHeader__weekFlicker" />
            <div className="wallchartHeader__daysOfWeek">
              {workWeekDays.map((day, key) => (
                <HeaderDay key={key}>
                  <div className={`wallchartHeader__day ${isActive(day) ? 'wallchartHeader__day--active' : ''}`}>
                    <BodyRegular weight={600} colour={color(day)}>{day.format('ddd')}</BodyRegular>
                    <BodyRegular weight={600} className="wallchartHeader__date"
                                 colour={color(day)}>{day.format('DD')}</BodyRegular>
                  </div>
                </HeaderDay>
              ))}
            </div>
          </WallChartContent>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div<any>`
  position: relative;
  // width: ${props => props.fixDateBar ? 'calc(100% - 32px)' : '100%'};
  // max-width: ${props => props.fixDateBar ? 'calc(100% - 32px)' : '100%'};
  height: 130px;
  max-width: 100%;
  width: 100%;
  @media (${fromMobile}) {
  }
  @media (${largeMobile}) {
    height: 70px;
  }
  @media (${desktopSmall}) {
    //width: calc(100% - 288px);
    //max-width: 805px;
     max-width: ${props => props.fixDateBar ? 'calc(75% - 25px)' : '100%'};
  }
  @media (${desktop1100}) {
    //max-width: inherit;
    max-width: ${props => props.fixDateBar ? 'calc(75% - 25px)' : '100%'};
  }
  @media (${desktopMidWide}) {
    max-width: ${props => props.fixDateBar ? '1026px' : '100%'};
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: inherit;
  max-width: inherit;
  overflow: hidden;
`

const WallChartContent = styled.div<any>`
  background-color: white;
  width: inherit;
  max-width: inherit;
  position: ${props => props.fixDateBar ? 'fixed' : 'relative'};
  top: ${props => props.fixDateBar ? '0px' : 'unset'};
  z-index: ${props => props.fixDateBar ? '1001' : 'unset'};
  left: ${props => props.fixDateBar ? `0` : `0`};
  @media (${desktopSmall}) {
    left: unset;
  }
`

export const HeaderDay = styled<any>(MovementsDayContainer)`
  justify-content: flex-end;
  min-height: 64px;
`

import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import IconButton from "../../../../../UI/molecules/icon-button/IconButton";
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { Checkbox } from "../../../../../UI/atoms/Checkbox";
import { UserInfoV2 } from "../ducks/ManageUsers.model";
import { UserAvatarAndName } from "../../../../../UI/molecules/UserAvatarAndName";
import { BodyRegular, BodySmall } from "../../../../../UI/atoms/fonts/Body";
import { Row } from "../../../../../UI/atoms/StructuralLayout";
import { UserResponsibilityBadges } from "../../../../../UI/molecules/UserResponsibilityBadges";
import { Pill } from "../../../../../UI/atoms/Pill";
import { UserRolePill } from "./UserRolePill";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { checkUser } from "../ducks/manage-users.duck";
import { tablet } from "../../../../../UI/atoms/MediaQueries";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";
import isMobile from "is-mobile";
import { WithIsUserChecked } from "../hooks/WithIsUserChecked";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { getFullOfficeTitleById } from "../../../../../../utils/OfficeHelper";
import { ResendInviteButton } from "./ResendInviteButton";

export function UserItem(props: Props) {
  const {user} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const offices = useSelector(selectAllOfficeEntities);
  const isChecked = WithIsUserChecked(user);

  const hasUserLoggedIn = user.hasLoggedIn;

  const onCheckboxChange = (val: boolean) => {
    dispatch(checkUser({userId: user.id, state: val}));
  }

  const onClick = () => {
    if (isMobile()) {
      dispatch(redirectTo(`/manage/user/${user.id}`));
    }
  }

  const officeName = useMemo(() => {
    if (user.officeId && offices.length > 0) {
      return getFullOfficeTitleById(user.officeId, offices, ', ');
    }
    return '';
  }, [offices, user]);

  return (
    <Container hasUserLoggedIn={hasUserLoggedIn}>
      <UserInfo hasUserLoggedIn={hasUserLoggedIn} onClick={onClick}>
        <UserPersonalInfoColumn>
          <UserAvatarAndName firstName={user.firstName} lastName={user.lastName} colour={hasUserLoggedIn ? "blue" : "grey"}  />
          <BodySmall weight={400}>{user.email}</BodySmall>
        </UserPersonalInfoColumn>
        <UserOfficeInfoColumn>
          {user.teamName && <BodyRegular weight={400}>{user.teamName}</BodyRegular>}
          {user.defaultOfficeName && <Row>
            <Icon style={{marginRight: 8}} icon={IconTypes.Office} color={hasUserLoggedIn ? 'black' : 'grey'} />
            <BodyRegular weight={400}>{officeName}</BodyRegular>
          </Row>}
          <UserResponsibilityBadges user={user} />
        </UserOfficeInfoColumn>
        <UserInfoColumn>
          <UserRolePill user={user} />
          {!user.hasLoggedIn && <>
            <Pill style={{width: 130}} colour={Colours.plannerOrange} backgroundColour={Colours.plannerOrange}>
              <BodySmall weight={400}>{t('manage-users-page.not-logged-in')}</BodySmall>
            </Pill>
            <ResendInviteButton user={user} />
          </>}
        </UserInfoColumn>
      </UserInfo>
      <Controls>
        <IconButton icon={IconTypes.Edit} size={"medium"} link={`/manage/user/${user.id}`} />
        <Checkbox value={isChecked} onChange={onCheckboxChange} />
      </Controls>
    </Container>
  )
}

interface Props {
  user: UserInfoV2;
}

const Container = styled.div<any>`
  width: 100%;
  min-height: 110px;
  margin: 0 0 16px 0;
  border-radius: 12px;
  border: 1px solid ${Colours.lightGrey};
  background: ${props => props.hasUserLoggedIn ? Colours.white : Colours.veryLightGrey};
  display: flex;
  justify-content: space-between;
`

const UserInfo = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 24px 32px 16px 32px;
  flex: 1;
  .userAvatarAndName__name {
    font-weight: 600;
    color: ${props => props.hasUserLoggedIn ? Colours.black : Colours.darkGrey};
  }
  .userAvatarAndName__avatar {
    color: ${props => props.hasUserLoggedIn ? Colours.white : Colours.veryLightGrey};
  }
  p {
    color: ${props => props.hasUserLoggedIn ? Colours.black : Colours.darkGrey};
  }
  .userResponsibilityBadges {
    display: flex;
    margin: 0;
  }
  @media (${tablet}) {
    flex-direction: row;
  }
`

const UserPersonalInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const UserOfficeInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const UserInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Controls = styled.div`
  border-left: 1px solid ${Colours.lightGrey};
  display: none;
  align-items: center;
  min-height: 100%;
  padding: 0 32px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: ${Colours.white};
  .checkbox__container {
    margin-bottom: 22px;
    margin-left: 22px;
  }
  @media (${tablet}) {
    display: flex;
  }
`

import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { UserItem } from "./UserItem";
import { UserListViewControl } from "./UserListViewControl";
import { UserInfoV2 } from "../ducks/ManageUsers.model";
import { useSelector } from "react-redux";
import { selectIsManageUsersLoading, selectUsers } from "../ducks/manage-users.duck";
import { ManageUsersPaginationControls } from "./ManageUsersPaginationControls";
import { LoadingSpinner } from "../../../../../UI/atoms/LoadingSpinner";
import { Center } from "../../../../../UI/atoms/StructuralLayout";

export function ManageUsersList(props: Props) {
  const users = useSelector(selectUsers);
  const isLoading = useSelector(selectIsManageUsersLoading);

  return (
    <Container>
      <UserListViewControl />
      {isLoading ? <Center><LoadingSpinner style={{margin: '50px 0'}} hideText={true} hideBorder={true} /></Center> : <>
        {users.map((user: UserInfoV2, key) => <UserItem key={key} user={user} />)}
      </>}

      <ManageUsersPaginationControls />
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  background-color: ${Colours.veryLightGrey};
  padding: 20px 16px;
  width: 100%;
`

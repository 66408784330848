import React from 'react';
import { Department } from "../../../../departments/data/departments.models";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";

export function DepartmentContentCard(props: Props) {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(openDialogWithPayload({
      activeDialog: DialogIdentifiers.EditDepartmentDialog,
      payload: {department: props.department}
    }));
  }
  return (
    <ContentCard onClick={onClick}>
      <BodyRegular weight={600}>{props.department.name}</BodyRegular>
      <BodyRegular>{props.department.count} members</BodyRegular>
    </ContentCard>
  )
}

interface Props {
  department: Department;
}


export const ContentCard = styled.div`
  margin-bottom: 24px;
  border: 1px solid ${Colours.mildGrey};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 24px 24px 24px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${Colours.blue};
  }
`

import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { UsersHolidayUsageViewWithDataProvider } from "./UsersHolidayUsageViewWithDataProvider";
import { Moment } from "moment";
import { BasicUserInfo } from "../../../../models/user.models";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../store/ducks/config.duck";

export function HolidayUsagePopup(props: Props) {
  const {user, date} = props;
  const config = useSelector(selectConfig);

  const holidayYearStartDate = useMemo(() => config.getHolidayYearStartDate(date), [config, date]);

  return (
    <Wrapper>
      <UserAvatarAndName firstName={user.firstName} lastName={user.lastName} />
      <UsersHolidayUsageViewWithDataProvider userId={user.userId} date={holidayYearStartDate} />
    </Wrapper>
  )
}

interface Props {
  date: Moment;
  user: BasicUserInfo;
}



const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  max-width: 448px;
  min-width: 300px;
  min-height: 50px;
  padding: 24px;
  position: absolute;
  top: 0;
  left: 200px;
  right: 0;
  z-index: 100;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  cursor: default;
  justify-content: center;
  p {
    max-width: 100%;
  }
  .userAvatarAndName {
    margin: 0 auto 16px auto;
  }
`

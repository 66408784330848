import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import { UpdateUserField, updateUserField } from "../../../../services/UserService";
import { Row } from "../../../UI/atoms/StructuralLayout";
import { SpaceBetweenRow } from "../StyleComponents";
import { Switch } from "../../../UI/atoms/Switch";

const MONTHS: {label: string, id: number}[] = [{label: 'January', id: 1}, {label: 'February', id: 2}, {label: 'March', id: 3}, {label: 'April', id: 4}, {label: 'May', id: 5}, {label: 'June', id: 6}, {label: 'July', id: 7}, {label: 'August', id: 8}, {label: 'September', id: 9}, {label: 'October', id: 10}, {label: 'November', id: 11}, {label: 'December', id: 12}]
function DAYS(monthLabel: number): number[] {
  if ([1,3,5,7,8,10,12].includes(monthLabel)) {
    return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
  } else if ([4,6,9,11]) {
    return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
  } else if (monthLabel === 2) {
    return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]
  } else {
    console.warn('Unknown month value', monthLabel)
    return [];
  }
}

export function BirthdaySection(props: Props) {
  const {t} = useTranslation();
  const {userId} = props;
  const [dayLoading, setDayLoading] = useState(false);
  const [monthLoading, setMonthLoading] = useState(false);
  const [day, setDay] = useState(props?.birthdayDay ?? 0);
  const [month, setMonth] = useState(props?.birthdayMonth ?? 0);
  const [enabled, setEnabled] = useState(!!props.birthdayMonth && !!props.birthdayDay);

  const onDaySelect = async (day: any) => {
    try {
      if (userId) {
        setDayLoading(true)
        await updateUserField(userId, UpdateUserField.birthdayDay, day);
        setDay(day);
      }
    } finally {
      setDayLoading(false);
    }
  }

  const onMonthSelect = async (month: any) => {
    try {
      if (userId) {
        setMonthLoading(true)
        await updateUserField(userId, UpdateUserField.birthdayMonth, month);
        setMonth(month);
      }
    } finally {
      setMonthLoading(false);
    }
  }

  const onSwitchToggled = async (value: boolean) => {
    try {
      if (!value && userId) {
        setMonthLoading(true)
        setDayLoading(true)
        await updateUserField(userId, UpdateUserField.birthdayMonth, 0);
        await updateUserField(userId, UpdateUserField.birthdayDay, 0);
        setMonth(0);
        setDay(0);
      }
      setEnabled(value);
    } finally {

    }
  }

  return (
    <Wrapper>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings-page.edit-user.birthday.title')}</BodyLarge>
        <Switch value={enabled} onChange={onSwitchToggled} />
      </SpaceBetweenRow>

      <BodyRegular style={{marginBottom: 24}}>{t('settings-page.edit-user.birthday.description')}</BodyRegular>

      {enabled && <Row>
        <Column style={{width: '25%', marginRight: 16}}>
          <BodyRegular weight={600}>{t('settings-page.edit-user.birthday.month')}</BodyRegular>
          <select className={'teamDropDownList'} disabled={monthLoading} value={month} onChange={(e) => onMonthSelect(e.target.value)}>
            <option disabled={true} value={0}> -- Month --</option>
            {MONTHS.map((item: any, k) => <option key={k} value={item.id}>{item.label}</option>)}
          </select>
        </Column>

        <Column>
          <BodyRegular weight={600}>{t('settings-page.edit-user.birthday.day')}</BodyRegular>
          <select className={'teamDropDownList'} disabled={dayLoading} value={day} onChange={(e) => onDaySelect(e.target.value)}>
            <option disabled={true} value={0}> -- Day --</option>
            {DAYS(month).map((item: number, k) => <option key={k} value={item}>{item}</option>)}
          </select>
        </Column>
      </Row>}

    </Wrapper>
  )
}

interface Props {
  birthdayMonth?: number;
  birthdayDay?: number;
  userId?: string;
}

const Wrapper = styled.div`
  .teamDropDownList {
    width: 100%;
  }
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 24px;
  padding-bottom: 12px;
`

const Column = styled.div`
  width: 47.5%
`

import React, { useState } from 'react';
import { agent } from '../../../services/agent';
import { OutlineButton } from "../atoms/buttons/OutlineButton";
import { useSelector } from "react-redux";
import { DATE_FORMAT } from "../../../utils/DateUtils";
import styled from "styled-components/macro";
import { selectSelectedOffice } from "../../../store/ducks/officeView.duck";
import { toExcelDownload } from "../../../services/ReportingService";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { canUserViewReportingPage, isTeamAdminUser } from "../../../utils/AccessControl";
import { selectActiveDay } from "../../../store/ducks/editMovements.duck";
import { SimpleSpinner } from "../atoms/SimpleSpinner";

export function DownloadDailyOfficeReportButton(props: Props) {
  const activeDate = useSelector(selectActiveDay);
  const selectedOffice = useSelector(selectSelectedOffice);
  const currentUser = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);
      if (!selectedOffice || !activeDate) return;
      const dateString = activeDate.format(DATE_FORMAT)
      const response = await agent.requests.get(`/api/report/office-attendees?date=${dateString}&officeId=${selectedOffice.id}`);
      toExcelDownload(response, `${selectedOffice.label} Office attendance for ${dateString}`);
    } finally {
      setLoading(false);
    }
  }

  if (!canUserViewReportingPage(currentUser) || isTeamAdminUser(currentUser)) return null;

  return (
    <Wrapper>
      <OutlineButton click={onClick} fullWidth={true} disabled={loading}
                     text={"button.download-office-attendees"} />
      {loading && <SimpleSpinner size={32} style={{marginTop: 6, marginLeft: 12}} />}
    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 6px 0;
  padding: 0 12px;
`

import React, { useMemo, useState } from 'react';
import { Column } from "../../../../UI/atoms/StructuralLayout";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { UserInfo } from "../../../../../models/user.models";
import { useDispatch, useSelector } from "react-redux";
import { selectIsEditUserLoading, setEditingUser } from "../../../../../store/ducks/editUser.duck";
import { selectCurrentUser, updateCurrentUser } from "../../../../../store/ducks/auth.duck";
import { UpdateUserField, updateUserField } from "../../../../../services/UserService";
import { successNotification, warningNotification } from "../../../../../store/ducks/notification.duck";
import DepartmentDropDownList from "../../../../UI/organisms/team-drop-down-list/DepartmentDropDownList";
import { Department } from "../../../departments/data/departments.models";
import styled from "styled-components/macro";

export function ChangeUsersDepartmentDropDownComponent(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const {departments, editingUser} = props;
  const isEditUserLoading = useSelector(selectIsEditUserLoading);
  const [requestProcessing, setRequestProcessing] = useState(false);

  const isLoading = useMemo(() => isEditUserLoading || requestProcessing, [isEditUserLoading, requestProcessing]);

  const onDepartmentSelected = async (departmentId: number) => {
    if (isLoading) return;

    try {
      setRequestProcessing(true);
      await updateUserField(editingUser.userId, UpdateUserField.department, departmentId);
      dispatch(setEditingUser({
        ...editingUser,
        departmentId: departmentId,
      }));
      // If user is editing themselves refresh current user.
      if (editingUser.userId === currentUser?.id) {
        dispatch(updateCurrentUser(true));
      }

      dispatch(successNotification('Users department updated'));
    } catch (e) {
      dispatch(warningNotification('Failed to update users department'));
    } finally {
      setRequestProcessing(false);
    }
  }

  return (
    <Container>
      <Column style={{flex: 1, marginRight: 8}}>
        <DepartmentDropDownList disabled={isLoading}
                                label="settings-page.edit-user.member-of-department"
                                onSelect={(departmentId: number) => onDepartmentSelected(departmentId)}
                                departments={departments}
                                selectedDepartmentId={editingUser.departmentId}
                                includeNoDepartment={true} />
      </Column>
      {isLoading && <SimpleSpinner style={{marginBottom: -12}} size={22} />}
    </Container>
  )
}

interface Props {
  departments: Department[];
  editingUser: UserInfo;
}

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
`

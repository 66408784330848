import React from 'react';
import { HeadlineMedium } from "./fonts/Headline";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { tablet } from "./MediaQueries";
import { BodyRegular } from "./fonts/Body";
import Colours from "./Colours";

export function PageTitle(props: Props) {
  const {text} = props;
  const {t} = useTranslation();
  return (
    <Wrapper className={'pageTitle'} style={props?.style ?? {}} includeMobileGuttres={!!props.includeMobileGutters}>
      <HeadlineMedium>{!!props.overrideText ? props.overrideText : t(text)}</HeadlineMedium>
      {props.subText && <BodyRegular style={{lineHeight: 'unset', marginLeft: 16}} weight={400} colour={Colours.darkGrey}>{props.subText}</BodyRegular>}
    </Wrapper>
  )
}

interface Props {
  text: string;
  subText?: string;
  style?: any;
  includeMobileGutters?: boolean;
  overrideText?: string;
}

const Wrapper = styled.div<any>`
  width: 100%;
  text-align: center;
  margin: ${props => props.includeMobileGutters ? '24px 16px 2px 16px' : '24px 0 2px 0'};
  display: flex;
  align-items: flex-end;
  h1 {
    font-size: 30px;
  }
  @media (${tablet}) {
    text-align: left;
    h1 {
      font-size: 44px;
    }
  }
`

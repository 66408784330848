import { agent } from './agent';

export interface WhereaboutsDayInfo {
  title: string;
  label: string;
}

export function getWhereaboutsInfo(date: string, userId: string): Promise<WhereaboutsDayInfo> {
  return agent.requests.get(`/api/v3/whereabouts/info/${date}/${userId}`)
}

import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../store/state/app.state";
import { requestUsersWeeklyMovements, requestWhereaboutsV2 } from "../../../../services/Movements";
import { WhereaboutsTeamViewResponse } from "../../../../models/api.models";
import {
  MemberMovements,
  sortWhereaboutsByDate,
  TagWithMovements,
  TeamWithMovements,
  UsersWhereaboutsDay
} from "../../../../models/movements.models";
import moment, { Moment } from "moment";

export interface TeamMovementsState {
  loading: boolean;
  visibleDate: Moment;
  teamWhereabouts: TeamWithMovements[];
  workgroupWhereabouts: TagWithMovements[];
  currentUsersWhereabouts?: MemberMovements;
}

export const initialTeamMovementsState: TeamMovementsState = {
  loading: false,
  visibleDate: moment(0),
  teamWhereabouts: [],
  workgroupWhereabouts: []
}

export const loadWhereabouts: any = createAsyncThunk(
  'teamMovements/loadWhereabouts',
  async (params: {calendarDate: Moment}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const calendarDate = params?.calendarDate ?? state.dashboard.calendarDate;

    const response: WhereaboutsTeamViewResponse = await requestWhereaboutsV2(calendarDate);
    const workgroups = (response?.workgroupsWhereabouts ?? []).map(ww => new TagWithMovements(ww.workGroup, true, true, ww.whereabouts, undefined, ww.restricted));
    const teams = (response?.teamWhereabouts ?? []).map(tw => new TeamWithMovements(tw.teamEntity, true, true, tw.teamWhereabouts, tw.userDesks, tw.restricted))

    return {
      teamWhereabouts: teams,
      workgroupWhereabouts: workgroups,
      visibleDate: calendarDate,
    }
  }
)

export const loadCurrentUsersWhereabouts: any = createAsyncThunk(
  'teamMovements/loadCurrentUsersWhereabouts',
  async (params: {calendarDate: Moment}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const currentUser = state.auth.currentUser
    const calendarDate = params?.calendarDate ?? state.dashboard.calendarDate;


    if (currentUser) {
      const currentUsersWhereabouts = await requestUsersWeeklyMovements(calendarDate, currentUser?.id);
      return {
        currentUsersWhereabouts
      }
    } else {
      return {};
    }
  }
)

export const updateCurrentUsersWhereabouts: any = createAsyncThunk(
  'teamMovements/updateCurrentUsersWhereabouts',
  async (params: {date: string, whereaboutsToReplace: UsersWhereaboutsDay}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const memberWhereabouts = state.teamMovements.currentUsersWhereabouts
    const whereaboutsToUpdate = params.whereaboutsToReplace;

    if (!memberWhereabouts) {
      return;
    } else {
      const updatedWhereaboutsDays = memberWhereabouts.whereabouts.map((w) => {
        if (w.date === whereaboutsToUpdate.date) {
          return whereaboutsToUpdate
        } else {
          return w
        }
      });
      return {
        ...memberWhereabouts,
        whereabouts: updatedWhereaboutsDays,
      }
    }
  }
)

export const updateTeamMembersWhereabouts: any = createAsyncThunk(
  'teamMovements/updateTeamMembersWhereabouts',
  async (params: {date: string, whereaboutsToReplace: UsersWhereaboutsDay}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const teamWhereabouts = state.teamMovements.teamWhereabouts
    const whereaboutsToUpdate = params.whereaboutsToReplace;

    const updatedTeamsWhereabouts = teamWhereabouts.map((teamWhereabouts: TeamWithMovements) => {
      if (teamWhereabouts.movements) {
        const updatedWhereabouts = teamWhereabouts.movements.map(memberMovements => {
          if (memberMovements.id === whereaboutsToUpdate.userId) {
            if (memberMovements.whereabouts.find(w => w.date === params.date)) {
              return {
                ...memberMovements,
                whereabouts: memberMovements.whereabouts.map(w => w.date === params.date ? whereaboutsToUpdate : w)
              }
            } else {
              const updatedMemberWhereabouts = [...memberMovements.whereabouts];
              updatedMemberWhereabouts.push(whereaboutsToUpdate);
              updatedMemberWhereabouts.sort(sortWhereaboutsByDate);
              return {
                ...memberMovements,
                whereabouts: updatedMemberWhereabouts,
              }
            }
          } else {
            return memberMovements;
          }
        });
        return {
          ...teamWhereabouts,
          movements: updatedWhereabouts,
        }
      }
      return teamWhereabouts;
    })

    return updatedTeamsWhereabouts;
  }
)



const teamMovementsSlice = createSlice({
  name: 'teamMovements',
  initialState: initialTeamMovementsState,
  reducers: {
    reset: () => initialTeamMovementsState,
  },
  extraReducers: {
    [loadWhereabouts.pending]: (state) => ({...state, loading: true,}),
    [loadWhereabouts.reject]: (state) => ({...state, loading: false,}),
    [loadWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      teamWhereabouts: action.payload.teamWhereabouts,
      workgroupWhereabouts: action.payload.workgroupWhereabouts,
      visibleDate: action.payload.visibleDate,
    }),

    [updateCurrentUsersWhereabouts.fulfilled]: (state, action) => ({...state, currentUsersWhereabouts: action.payload}),
    [updateTeamMembersWhereabouts.fulfilled]: (state, action) => ({...state, teamWhereabouts: action.payload}),

    [loadCurrentUsersWhereabouts.pending]: (state, action) => ({...state, currentUsersWhereabouts: undefined,}),
    [loadCurrentUsersWhereabouts.fulfilled]: (state, action) => ({...state, currentUsersWhereabouts: action.payload.currentUsersWhereabouts,}),
  }
})

export default teamMovementsSlice.reducer;
export const {
  reset,
} = teamMovementsSlice.actions;

// Selectors
export const selectIsTeamMovementsLoading = (state: AppState) => state.teamMovements.loading;
export const selectWhereabouts = (state: AppState) => ({
  workgroups: state.teamMovements.workgroupWhereabouts,
  teams: state.teamMovements.teamWhereabouts,
})

export const selectCurrentUsersWhereabouts = (state: AppState) => state.teamMovements.currentUsersWhereabouts;

import React from 'react';
import { Period } from "../../../models/movements.models";
import { WhereaboutsOption } from "../../../services/WhereaboutOptions";

function getOutlineIcon(option: WhereaboutsOption) {
  switch (option.colour) {
    case 'green': return option.image.replace('.svg', '-green.svg').toLowerCase()
    case 'yellow': return option.image.replace('.svg', '-yellow.svg').toLowerCase()
    default: return option.image.replace('.svg', '-grey.svg').toLowerCase()
  }
}

const SUPPORTED_IMAGES = [
  'House.svg', 'Offsite.svg', 'Flag.svg', 'HardHat.svg', 'ill.svg', 'Office.svg', 'Boat.svg', 'PendingHoliday.svg', 'Holiday.svg', 'aeroplane.svg', 'DryDock.svg', 'Port.svg', 'Volunteering.svg', 'euro.svg'
]

function isImageSupported(option: WhereaboutsOption) {
  return SUPPORTED_IMAGES.includes(option.image);
}

export function WhereaboutsIconOutline(props: Props) {
  const option = props.sideOfDay === Period.PM ? props.activity.pmOption : props.activity.amOption;
  const title = option.label

  const imageUrl = getOutlineIcon(option)
  const imageSupported = isImageSupported(option);

  if (imageSupported) {
    return <img title={title} style={{marginRight: 16}}
                className={"whereabouts__outlineIcon"}
                src={`/assets/icons/outline-icons/${imageUrl}`}
                onMouseOver={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onMouseLeave={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onTouchStart={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onTouchEnd={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                onTouchCancel={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
                alt="" />;
  } else {
    return <WhereaboutsIconOutlineUnknown stopPropagation={props.stopPropagation} />
  }
}

export function WhereaboutsIconOutlineUnknown(props: {stopPropagation?: boolean}) {
  return <img className={"whereabouts__outlineIcon"}
              style={{marginRight: 16}}
              src="/assets/icons/outline-icons/dot-outline.svg"
              onMouseOver={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onMouseLeave={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onTouchStart={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onTouchEnd={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              onTouchCancel={(e: any) => !!props.stopPropagation ? e.stopPropagation() : false}
              alt="" />
}

interface Props {
  activity: any;
  sideOfDay: Period;
  stopPropagation?: boolean;
}

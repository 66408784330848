import React, { useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../../../store/ducks/auth.duck";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";

export function GlobalEmailRemindersSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onChange = async (val: boolean) => {
    setIsLoading(true);
    if (user?.companyEntity) {
      try {
        // await updateCompany({
        //   ...user.companyEntity,
        //   disableEditingPreviousWhereabouts: val
        // });
        dispatch(setUser({
          ...user,
          companyEntity: {
            ...user.companyEntity,
          }
        }))
      } finally {
        setIsLoading(false);
      }
    } else {
      dispatch(warningNotification('No company attached to user'))
    }
  }

  const enabled = false;

  if (enabled) {
    return (
      <ConfigurationSection>
        <SpaceBetweenRow>
          <BodyLarge colour={Colours.blue}>{t('settings.global-email-setting.title')} TBC</BodyLarge>
          {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.disableEditingPreviousWhereabouts} label="" onChange={onChange}/>}
        </SpaceBetweenRow>
        <BodyRegular style={{marginBottom: 8}}>{t('settings.global-email-setting.description')}</BodyRegular>
      </ConfigurationSection>
    )
  } else {
    return null;
  }

}

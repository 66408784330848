import React, { useState } from 'react';
import { UserInfoV2 } from "../ducks/ManageUsers.model";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { agent } from "../../../../../../services/agent";
import { useDispatch } from "react-redux";
import { failureNotification, successNotification } from "../../../../../../store/ducks/notification.duck";

export function ResendInviteButton(props: Props) {
  const showButton = !props.user.hasLoggedIn;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  if (!showButton) {
    return null;
  }

  const onClick = async () => {
    try {
      setLoading(true);
      const response = await agent.requests.post('/api/user/management/resend-invite', {userId: props.user.id});
      if (response.success) {
        dispatch(successNotification('Invite re-sent'));
      } else {
        dispatch(failureNotification(response.error));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <OutlineButton click={onClick}
                   disabled={loading}
                   size={'small'}
                   style={{width: 130, marginTop: 4}}
                   text={'manage-users-page.resend-invite'} />
  )
}

interface Props {
  user: UserInfoV2;
}

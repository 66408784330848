import React, { useState } from 'react';
import { BlueContentCard } from "../../../../../UI/atoms/ContentCard";
import styled from "styled-components/macro";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import Colours from "../../../../../UI/atoms/Colours";
import { OutlookMeetingRoomLookup } from "../OutlookMeetingRoomLookup";

export function OutlookMeetingRoomHelpCard(props: Props) {
  const [showOutlookLink, setShowOutlookLink] = useState(false);
  const onMeetingRoomIdChange = (id: string) => {

  }
  return (
    <BlueContentCard style={{marginBottom: 24}}>
      <Container>
        {showOutlookLink ? <OutlookMeetingRoomLookup loading={false} microsoftRoomId={''} onChange={onMeetingRoomIdChange} /> : <>
          <LeftColumn>
            <BodyRegular colour={Colours.darkBlue} weight={600}>Avoid double booking by linking this room to Outlook calendar</BodyRegular>
            <BodyRegular>Use the rooms email address to sync Team Today and Outlook meeting rooms.</BodyRegular>
            <BodyRegular style={{marginBottom: 16}}>This will require IT Admin permissions.</BodyRegular>
            <OutlineButton click={() => setShowOutlookLink(!showOutlookLink)} text={'Set up permissions'} />
          </LeftColumn>

          <img style={{maxWidth: 200}} src={"/assets/images/meeting-room-graphic-1.svg"} alt={""} />
        </>}
      </Container>
    </BlueContentCard>
  )
}

interface Props {
}

const LeftColumn = styled.div`
  max-width: 400px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  img {
    max-width: 240px;
    margin-left: auto;
  }
`

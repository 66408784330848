import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import DialogHeader from "../../../../../dialogs/DialogHeader";
import Dialog from "../../../../../UI/molecules/Dialog";
import { closeDialog } from "../../../../../../store/ducks/dialog.duck";
import { assignUsersToDepartment, selectAllCheckedUsers, selectIsManageUsersLoading } from "../ducks/manage-users.duck";
import { Department } from "../../../../departments/data/departments.models";
import { DepartmentsMultiSelectDropDownList } from "../../../../../UI/organisms/DepartmentsMultiSelectDropDownList";

export default function AssignToDepartmentDialog(props: Props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<Department | undefined>();
  const selectedUsers = useSelector(selectAllCheckedUsers);
  const isLoading = useSelector(selectIsManageUsersLoading);

  const close = () => {
    dispatch(closeDialog());
  }

  const done = () => {
    if (selection && selectedUsers.length > 0) {
      dispatch(assignUsersToDepartment(selection.id));
      close();
    }
  }

  const onDepartmentsSelected = (selectedDepartment: Department) => {
    setSelection(selectedDepartment);
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-users-page.assign-to-department" />

      <DepartmentsMultiSelectDropDownList label={'Select department to add user(s) to'}
                                          singleSelect={true}
                                          onSelect={(department: any) => onDepartmentsSelected(department)}
                                          disabled={false} />

      <PrimaryButton disabled={isLoading}
                     fullWidth={true}
                     style={{marginBottom: 16, marginTop: 'auto'}}
                     className={'saveChangesButton'}
                     click={done}
                     text="button.save-changes" />
    </DialogWrapper>
  )
}

interface Props {
  payload: any;
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
  }
  .dialog__contentContainer, .dialog__content {
    width: 400px;
    min-height: 510px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
  .saveChangesButton {
    margin-top: auto;
  }
`


import React, { useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { Link } from 'react-router-dom';
import { updateLineManagementSetting } from "../../../../store/ducks/onboarding.duck";
import { Trans, useTranslation } from "react-i18next";

export function LineManagementSettings(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onChange = (val: boolean) => {
    setIsLoading(true);
    try {
      dispatch(updateLineManagementSetting(val));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ConfigurationSection>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.line-management.title')}</BodyLarge>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.lineManagementEnabled} label="" onChange={onChange}/>}
      </SpaceBetweenRow>
      {user?.companyEntity.lineManagementEnabled ? <>
        <BodyRegular><Trans i18nKey={'settings.line-management.enabled-message'} components={[<Link to={'/manage/users?view=user'}>{t('user-view')}</Link>]} /></BodyRegular>
      </> : <>
        <BodyRegular style={{marginBottom: 8}}>{t('settings.line-management.disabled-message')}</BodyRegular>
      </>}
    </ConfigurationSection>
  )
}

interface Props {
}

import React, { useEffect, useMemo, useState } from 'react';
import { YearContainer } from "../../../holidays-v2/user-annual-holidays/components/YearContainer";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../../store/ducks/config.duck";
import moment from "moment";
import DateFlicker, { DateScale } from "../../../../UI/molecules/DateFlicker";
import { HeadlineLarge } from "../../../../UI/atoms/fonts/Headline";
import { BodyRegular } from '../../../../UI/atoms/fonts/Body';
import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { Sidebar } from "../../../../UI/atoms/Sidebar";
import { CompanyHolidaysList } from "./components/CompanyHolidaysList";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { CompanyHolidayOverview, getCompanyHolidayList } from "../../../../../services/CompanyHolidayService";
import { selectCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import AccessRequired from "../../components/AccessRequired";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { canChangeCompanyHolidays } from "../../../../../utils/AccessControl";
import { useTranslation } from "react-i18next";

export function CompanyHolidaysPage(props: Props) {
  const config = useSelector(selectConfig);
  const calendarDate = useSelector(selectCalendarDate);
  const currentUser = useSelector(selectCurrentUser);
  const [date, setDate] = useState(calendarDate ?? moment());
  const [companyHolidays, setCompanyHolidays] = useState<CompanyHolidayOverview | undefined>(undefined);
  const {t} = useTranslation();

  const canEdit = useMemo(() => canChangeCompanyHolidays(currentUser), [currentUser]);

  const holidayYearStartDate = useMemo(() => {
    return config.getHolidayYearStartDate(date.clone());
  }, [date, config]);

  useEffect(() => {
    getCompanyHolidayList(date.year()).then(resp => setCompanyHolidays(resp));
  }, [date]);

  return (
    <Container>
      <PageHeader>
        <GoBackButton url={"/manage"} />
        <HeadlineLarge style={{marginBottom: 24}}>{t('company-holiday.title')}</HeadlineLarge>
        <AccessRequired visible={!canEdit} companyAdminsRequired={true} />
        <SpaceBetweenRow>
          <BodyRegular className={"companyHolidayPageHeader__description"}>{t('company-holiday.description')}</BodyRegular>
          <DateFlicker date={date.clone()} dateScale={DateScale.Year} updateGlobalDate={false} onChange={setDate} />
        </SpaceBetweenRow>
      </PageHeader>

      <Content>
        <Sidebar>
          <CompanyHolidaysList disabled={!canEdit} activeDate={holidayYearStartDate} companyHolidays={companyHolidays} />
        </Sidebar>
        <YearContainer holidaysBooked={[]}
                       colourOverride={'green'}
                       startDate={holidayYearStartDate}
                       companyHolidays={companyHolidays?.companyHolidays ?? []} />
      </Content>
    </Container>
  )
}

interface Props {
}


const Container = styled.div`
  max-width: 100%;
  background-color: white;
`

const SpaceBetweenRow = styled.div`
  display: flex;
  flex-direction: column;
  @media (${tablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (${tablet}) {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 32px;
  }
`

const PageHeader = styled.div`
  padding: 32px 32px 48px 32px;
  .companyHolidayPageHeader__description {
    max-width: 565px;
  }
  .weekFlicker {
    width: 100%;
    @media (${tablet}) {
      width: unset;
      min-width: 300px;
    }
  }
`

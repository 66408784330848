import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../store/state/app.state";
import moment, { Moment } from "moment";
import { UsersHolidayInfo } from "./Holidays.model";
import { getUsersHolidayInfoForGroup } from "../../../../services/HolidayService";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { SELECTED_HOLIDAY_REPORTS_GROUP_ID } from "../holiday-reports-page/components/HolidayReportViewHeader";

export interface HolidayReportsState {
  loading: boolean;
  userNameSearchQuery: string;
  selectedTypeOfGroupsToShow: string;
  holidayReportingDate: Moment;
  listOfUsersHolidayInfo: UsersHolidayInfo[];
}

export const initialHolidayReportsState: HolidayReportsState = {
  loading: false,
  userNameSearchQuery: '',
  selectedTypeOfGroupsToShow: localStorage.getItem(SELECTED_HOLIDAY_REPORTS_GROUP_ID) || 'LINE_REPORTS',
  holidayReportingDate: moment(),
  listOfUsersHolidayInfo: [],
}

export const loadUserHolidayInfo: any = createAsyncThunk(
  'holidayReports/loadUserHolidayInfo',
  async (params: any, thunkAPI) => {
    const holidayReports = (thunkAPI.getState() as AppState).holidayReports;
    const group = params?.group ?? holidayReports.selectedTypeOfGroupsToShow;
    const response = await getUsersHolidayInfoForGroup(group, holidayReports.holidayReportingDate);
    return {
      usersHolidayInfo: naturalSort(response, 'fullName'),
    }
  }
)

export const userNameSearch: any = createAsyncThunk(
  'holidayReports/userNameSearch',
  async (query: string, thunkAPI) => {
    return {
      userNameSearch: query,
    }
  }
)

export const setHolidayReportingDate: any = createAsyncThunk(
  'holidayReports/setHolidayReportingDate',
  async (query: Moment, thunkAPI) => {
    return {
      holidayReportingDate: query,
    }
  }
)

export const setSelectedTypeOfGroupsToShow: any = createAsyncThunk(
  'holidayReports/setSelectedTypeOfGroupsToShow',
  async (typeOfGroups: string, thunkAPI) => {

    localStorage.setItem(SELECTED_HOLIDAY_REPORTS_GROUP_ID, typeOfGroups);
    thunkAPI.dispatch(loadUserHolidayInfo({group: typeOfGroups}));
    return {
      selectedTypeOfGroupsToShow: typeOfGroups,
    }
  }
)

const holidayReportsSlice = createSlice({
  name: 'holidayReports',
  initialState: initialHolidayReportsState,
  reducers: {
    reset: () => initialHolidayReportsState,
  },
  extraReducers: {
    [userNameSearch.pending]: (state) => ({...state, loading: true,}),
    [userNameSearch.reject]: (state) => ({...state, loading: false,}),
    [userNameSearch.fulfilled]: (state, action) => ({ ...state, userNameSearchQuery: action.userNameSearch, loading: false, }),

    [loadUserHolidayInfo.pending]: (state) => ({...state, loading: true, listOfUsersHolidayInfo: []}),
    [loadUserHolidayInfo.reject]: (state) => ({...state, loading: false,}),
    [loadUserHolidayInfo.fulfilled]: (state, action) => ({ ...state, listOfUsersHolidayInfo: action.payload.usersHolidayInfo, loading: false, }),

    [userNameSearch.fulfilled]: (state, action) => ({ ...state, holidayReportingDate: action.payload.holidayReportingDate, loading: false, }),
    [setSelectedTypeOfGroupsToShow.fulfilled]: (state, action) => ({ ...state, selectedTypeOfGroupsToShow: action.payload.selectedTypeOfGroupsToShow, loading: false, }),
    [setHolidayReportingDate.fulfilled]: (state, action) => ({ ...state, holidayReportingDate: action.payload.holidayReportingDate, loading: false, }),
  }
})

export default holidayReportsSlice.reducer;
export const {
  reset,

} = holidayReportsSlice.actions;

// Selectors
export const selectIsHolidayReportsBookingsLoading = (state: AppState) => state.holidayReports.loading;
export const selectUserNameSearchQuery = (state: AppState) => state.holidayReports.userNameSearchQuery;
export const selectTypeOfGroupsToShow = (state: AppState) => state.holidayReports.selectedTypeOfGroupsToShow;
export const selectHolidayReportingDate = (state: AppState) => state.holidayReports.holidayReportingDate;
export const selectListOfUsersHolidayInfo = (state: AppState) => state.holidayReports.listOfUsersHolidayInfo;

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import DialogHeader from "../../dialogs/DialogHeader";
import Dialog from "../../UI/molecules/Dialog";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { Department } from "./data/departments.models";
import Colours from "../../UI/atoms/Colours";
import TextField from "../../UI/atoms/TextField";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { BorderlessButton } from "../../UI/atoms/buttons/BorderlessButton";
import { deleteDepartment } from "./data/departmentsService";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { createNewDepartment, selectAllDepartments, setDepartments, updateDepartment } from "./data/departments.duck";
import { loadConfig } from "../../../store/ducks/config.duck";

export function EditDepartmentDialog(props: Props) {
  const [loading, setLoading] = useState(false);
  const departments = useSelector(selectAllDepartments);
  const dispatch = useDispatch();
  const isEditing = !!props.payload.department;
  const [name, setName] = useState(props?.payload?.department?.name ?? '');

  const onDeleteClick = async () => {
    if (props.payload.department && props.payload.department.id) {
      try {
        setLoading(true);
        await deleteDepartment(props.payload.department);
        dispatch(setDepartments(departments.filter(d => d.id !== props?.payload?.department?.id)));
        dispatch(loadConfig());
        dispatch(closeDialog());
      } catch (e) {
        dispatch(failureNotification('Failed to delete department'))
      } finally {
        setLoading(false);
      }
    }
  }

  const close = () => {
    dispatch(closeDialog());
  }

  const save = () => {
    if (isEditing) {
      if (props.payload.department) {
        dispatch(updateDepartment({...props.payload.department, name: name}))
        close();
      }
    } else {
      dispatch(createNewDepartment({name: name}))
      close();
    }
  }

  return (
    <>
      <DialogWrapper>
        <Dialog isOpen={true} onClose={close}>
          <DialogHeader title={isEditing ? "department-dialog.name" : "settings.departments.add-new"} />

          <TextField onChange={(value: string) => setName(value)}
                     label={"department-dialog.name"}
                     value={name} />

          {props.payload.department && <TextField onChange={() => {}}
                                                  disabled={true}
                                                  style={{marginBottom: 48}}
                                                  label={"department-dialog.users"}
                                                  value={props.payload.department.count.toString()} />}

          <PrimaryButton text={"button.save"}
                         click={save}
                         style={{marginBottom: 24}}
                         fullWidth={true} />

          {isEditing && <BorderlessButton text={"button.delete"}
                                          click={onDeleteClick}
                                          disabled={(props.payload.department?.count ?? 0) > 0 || loading}
                                          fullWidth={true} />}



        </Dialog>
      </DialogWrapper>
    </>
  )
}

interface Props {
  payload: {
    department?: Department;
  };
}

const DialogWrapper = styled.div`
  .dialog {
    min-width: 600px;
  }
  .userAvatarAndName {
    margin-bottom: 24px;
  }
  .switch p {
    font-weight: 600;
    color: ${Colours.darkGrey};
  }
`

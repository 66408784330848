import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTeams } from "../../../../../../store/ducks/config.duck";
import {
  selectSelectedDepartment,
  selectSelectedMisc,
  selectSelectedOffice,
  selectSelectedTeam,
  setSelectedTeam
} from "../ducks/manage-users.duck";

export function WithTeams() {
  const teams = useSelector(selectAllTeams);
  const selectedTeam = useSelector(selectSelectedTeam);
  const selectedOffice = useSelector(selectSelectedOffice);
  const selectedDepartment = useSelector(selectSelectedDepartment);
  const selectedMiscItem = useSelector(selectSelectedMisc);
  const dispatch = useDispatch();
  return useMemo(() => {
    const totalUsers = teams.map(t => t.memberCount).reduce((partialSum, a) => partialSum + a, 0);
    const teamsList = [
      {id: 'ALL', name: 'All Users', memberCount: totalUsers},
      ...teams
    ]

    if (!selectedTeam && !selectedOffice && !selectedMiscItem && !selectedDepartment) {
      dispatch(setSelectedTeam(teamsList[0]));
    }

    return teamsList;
  }, [teams, selectedTeam, selectedOffice, selectedMiscItem, selectedDepartment, dispatch]);
}

import React, { useMemo } from 'react';
import { RoomBookingSource } from "../models/MeetingRooms";
import styled from "styled-components/macro";
import { Pill } from "../../../UI/atoms/Pill";
import Colours from "../../../UI/atoms/Colours";
import { BodySmall } from "../../../UI/atoms/fonts/Body";

export function MeetingRoomBookingSourcePill(props: Props) {
  const {source} = props;

  const label = useMemo(() => {
    switch (source) {
      case RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK: return 'Booking linked to Outlook'
      case RoomBookingSource.TEAM_TODAY: return 'Team Today'
      case RoomBookingSource.EDITED_SYSTEM_LINKED_TO_OUTLOOK: return 'Matched to edited booking'
      case RoomBookingSource.OUTLOOK: return 'Outlook booking, no details'
      default: return source;
    }
  }, [source]);

  const toolTip = useMemo(() => {
    switch (source) {
      case RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK: return 'Booking linked to Outlook'
      case RoomBookingSource.TEAM_TODAY: return 'Team Today'
      case RoomBookingSource.EDITED_SYSTEM_LINKED_TO_OUTLOOK: return 'Matched to edited booking'
      case RoomBookingSource.OUTLOOK: return 'Outlook booking, no details'
      default: return source;
    }
  }, [source]);

  return (
    <Container>
      <Pill title={toolTip} colour={Colours.blue} backgroundColour={Colours.blue02}>
        <BodySmall>{label}</BodySmall>
      </Pill>
    </Container>
  )
}

interface Props {
  source: RoomBookingSource;
}

const Container = styled.div`
  
`

import React, { useMemo, useState } from 'react';
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import { BasicUserInfo, UserInfo } from "../../../../models/user.models";
import styled from "styled-components/macro";
import { Center, Row } from '../../../UI/atoms/StructuralLayout';
import { OutlineButton } from '../../../UI/atoms/buttons/OutlineButton';
import UserSearchDialog, { UserSelectType } from '../../../dialogs/user-search-dialog/UserSearchDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLineReport,
  removeLineReport,
  selectEdittingUser,
  selectLineReports
} from '../../../../store/ducks/editUser.duck';
import { UserAvatarAndName } from '../../../UI/molecules/UserAvatarAndName';
import IconButton from '../../../UI/molecules/icon-button/IconButton';
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { canManageLineReports } from "../../../../utils/AccessControl";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";

interface Props {
  user: UserInfo;
}

export default function UsersLineReports(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentEdittingUser = useSelector(selectEdittingUser);
  const lineReports = useSelector(selectLineReports);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const canUserManageLineReports = useMemo(() => canManageLineReports(currentUser), [currentUser]);
  const {t} = useTranslation();

  const orderedLineReports = useMemo(() => {
    try {
      return naturalSort([...lineReports], 'fullName')
    } catch (e: any) {
      return lineReports;
    }
  }, [lineReports]);

  const userSearchDialogClose = () => {
    setIsDialogOpen(false);
  }

  const onUserSelect = (user: BasicUserInfo) => {
    if (currentEdittingUser) {
      const isLineReport = !!orderedLineReports.find(lr => lr.userId === user.userId);

      if (isLineReport) {
        dispatch(removeLineReport({subordinateUserId: user.userId, reportingToUserId: currentEdittingUser.userId}));
      } else {
        dispatch(addLineReport({subordinateUserId: user.userId, reportingToUserId: currentEdittingUser.userId}));
      }
    }
  }

  const onDeleteClicked = (lineReport: BasicUserInfo) => {
    if (currentEdittingUser) {
      dispatch(removeLineReport({subordinateUserId: lineReport.userId, reportingToUserId: currentEdittingUser.userId}));
    }
  }

  if (!currentUser?.companyEntity.lineManagementEnabled) {
    return null;
  }

  return (
    <Wrapper className="" data-test="location-section">
      {isDialogOpen && <UserSearchDialog onClose={userSearchDialogClose}
                                          title="dialog-headers.select-line-reports"
                                          description="dialog.selected-number-of-users-to-report-to-a-user"
                                          type={UserSelectType.multiSelect}
                                          onUserSelect={onUserSelect}
                                          selectedUsers={orderedLineReports} />}
      <BodyLarge colour={Colours.blue} style={{marginBottom: 6}}>{t('settings.line-reports')}</BodyLarge>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.users-reporting-to-this-user')}</BodyRegular>
      {orderedLineReports.length  > 0 ?
        <>
          {orderedLineReports.map((lineReport: BasicUserInfo, key: any) => (
            <LineReportRow key={key} className={"lineReports__lineReport"}>
              <UserAvatarAndName firstName={lineReport.firstName} lastName={lineReport.lastName} />
              <IconButton icon={IconTypes.Bin} onClick={() => onDeleteClicked(lineReport)} disabled={!canUserManageLineReports} />
            </LineReportRow>
          ))}
        </>
      :
      (
        <Row>
          <Center style={{backgroundColor: Colours.veryLightGrey, alignItems: 'center', borderRadius: 16, minHeight: 80, marginBottom: 24}}>
            <BodyRegular colour={Colours.darkGrey}>{t('settings.no-line-reports')}</BodyRegular>
          </Center>
        </Row>
      )
      }
      <Center style={{marginBottom: 24}}>
        <OutlineButton click={() => setIsDialogOpen(true)}
                       disabled={!canUserManageLineReports}
                       dataTest={'add-new-line-report-button'}
                       text="button.add-new-line-report" />
      </Center>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  .teamDropDownList {
    width: 100%;
  }
  border-bottom: 1px solid ${Colours.mildGrey};
  border-top: 1px solid ${Colours.mildGrey};
  margin-bottom: 24px;
  padding-bottom: 12px;
  padding-top: 16px;
  margin-top: 16px;
`

const LineReportRow = styled.div`
  margin: 8px 0;
  padding: 8px 4px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${Colours.blue10};
  }
`

import React from 'react';
import { ConfigurationSection } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { buildNumber } from "../../../../services/EnvironmentVariables";

export function BuildVersion() {
  return (
    <ConfigurationSection>
      <BodyLarge colour={Colours.blue}>Version details</BodyLarge>
      <BodyRegular>Build: {buildNumber}</BodyRegular>

    </ConfigurationSection>
  )
}

import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components/macro";
import { WhereaboutsOption } from "../../../../../../services/WhereaboutOptions";
import { Moment } from "moment/moment";
import Colours from "../../../../atoms/Colours";
import { getWhereaboutsInfo, WhereaboutsDayInfo } from "../../../../../../services/WhereaboutsInfoService";
import { DATE_FORMAT } from "../../../../../../utils/DateUtils";
import { Center } from "../../../../atoms/StructuralLayout";
import { SimpleSpinner } from "../../../../atoms/SimpleSpinner";
import { BodySmall } from "../../../../atoms/fonts/Body";

export function WhereaboutsHoverInfo(props: Props) {
  const {date, userId} = props;
  const dateString = date.format(DATE_FORMAT);
  const [loading, setLoading] = useState(false);
  const [whereaboutsInfo, setWhereaboutsInfo] = useState<WhereaboutsDayInfo | undefined>();
  const timerRef = useRef(0);

  useEffect(() => {
    const loadWhereaboutsInfo = async () => {
      setLoading(true);
      try {
        const info = await getWhereaboutsInfo(dateString, userId);
        setWhereaboutsInfo(info);
      } finally {
        setLoading(false);
      }

    }

    timerRef.current = setTimeout(() => loadWhereaboutsInfo(), 500);
  }, [dateString, userId]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, []);

  if ((!loading && !whereaboutsInfo) || (!whereaboutsInfo?.label && !whereaboutsInfo?.title)) {
    return null;
  }

  return (
    <Container>
      {(loading || !whereaboutsInfo) ? <>
        <Center>
          <SimpleSpinner size={22} />
        </Center>
      </> : <>
        <BodySmall weight={600}>{whereaboutsInfo.title}</BodySmall>
        <BodySmall>{whereaboutsInfo.label}</BodySmall>
      </>}
    </Container>
  )
}

interface Props {
  amStatus: WhereaboutsOption;
  pmStatus: WhereaboutsOption;
  date: Moment;
  userId: string;
}

const Container = styled.div`
  position: absolute;
  z-index: 999999;
  min-width: 200px;
  max-width: 100%;
  min-height: 40px;
  background-color: white;
  left: 10px;
  top: 105%;
  border-radius: 8px;
  border: 1px solid ${Colours.veryLightGrey};
  box-shadow: 0 0 17px 2px rgba(0, 87, 255, 0.15);
  padding: 4px 8px;
`

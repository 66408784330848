import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../../../store/state/app.state";
import { TeamEntity, TeamWithCount } from "../../../../../../models/team.models";
import { SortOptions, UserInfoDetails, UserInfoV2 } from "./ManageUsers.model";
import { agent } from "../../../../../../services/agent";
import { loadConfig } from "../../../../../../store/ducks/config.duck";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { Department } from "../../../../departments/data/departments.models";

export interface ManageUsersState {
  isLoading: boolean;
  users: UserInfoV2[];
  checkedUsers: string[];
  selectedTeam?: TeamWithCount;
  selectedOffice?: OfficeEntity;
  selectedDepartment?: Department;
  selectedMisc?: string;
  sortOption: SortOptions;
  info?: UserInfoDetails;
  skip: number;
  totalUsers: number;
  searchQuery?: string;
  checkAllUsersChecked: boolean;
}

export const initialManageUsersState: ManageUsersState = {
  isLoading: false,
  users: [],
  checkedUsers: [],
  sortOption: SortOptions.usernameAz,
  skip: 0,
  totalUsers: 0,
  checkAllUsersChecked: false,
}

export const loadDetails: any = createAsyncThunk(
  'manageUsers/loadDetails',
  async (blank: string, thunkAPI) => {
    let url = '/api/user/management/details';
    return await agent.requests.get(url);
  }
)

export const loadUsers: any = createAsyncThunk(
  'manageUsers/loadUsers',
  async (params: {team?: TeamEntity, office?: OfficeEntity, department?: Department, misc?: string, skip?: number, count?: number, orderBy?: string, searchTerm?: string}, thunkAPI) => {
    let qs = []

    if (params && params.team && params.team.id !== 'ALL') {
      qs.push(`teamId=${params.team.id}`);
    } else if (params && params.office) {
      qs.push(`officeId=${params.office.id}`)
    } else if (params && params.department) {
      qs.push(`departmentId=${params.department.id}`)
    } else if (params && params.misc) {
      qs.push(`misc=${params.misc}`)
    }

    if (params && params.searchTerm) qs.push(`q=${params.searchTerm}`);
    if (params && params.skip) qs.push(`skip=${params.skip}`);
    if (params && params.orderBy) qs.push(`order=${params.orderBy}`);
    if (params && params.count) qs.push(`count=${params.count}`);

    let url = `/api/user/user-list${qs.length > 0 ? `?${qs.join('&')}` : ''}`;

    const response = await agent.requests.get(url);
    return response;
  }
)

export const assignUsersToDepartment: any = createAsyncThunk(
  'manageUsers/assignUsersToDepartment',
  async (departmentIdToAssignTo: number, thunkAPI) => {
    const checkedUsers = selectAllCheckedUsers(thunkAPI.getState() as AppState);
    console.log('toDepartment', checkedUsers)
    if (checkedUsers.length > 0) {
      const body = {
        departmentId: departmentIdToAssignTo,
        userIds: checkedUsers.map(u => u.id),
      }
      await agent.requests.put('/api/user/management/assign-to-department', body);
      thunkAPI.dispatch(loadConfig());
      thunkAPI.dispatch(loadUsers());
    }
  }
)

export const assignUsersToTeam: any = createAsyncThunk(
  'manageUsers/assignUsersToTeam',
  async (teamIdToAssignTo: string, thunkAPI) => {
    const checkedUsers = selectAllCheckedUsers(thunkAPI.getState() as AppState);
    if (checkedUsers.length > 0) {
      const body = {
        teamId: teamIdToAssignTo,
        userIds: checkedUsers.map(u => u.id),
      }
      await agent.requests.put('/api/user/management/assign-to-team', body);
      thunkAPI.dispatch(loadConfig());
      thunkAPI.dispatch(loadUsers());
    }
  }
)

export const assignUsersToOffice: any = createAsyncThunk(
  'manageUsers/assignUsersToTeam',
  async (officeIdToAssignTo: number, thunkAPI) => {
    const checkedUsers = selectAllCheckedUsers(thunkAPI.getState() as AppState);
    if (checkedUsers.length > 0) {
      const body = {
        officeId: officeIdToAssignTo,
        userIds: checkedUsers.map(u => u.id),
      }
      await agent.requests.put('/api/user/management/assign-to-office', body);
      thunkAPI.dispatch(loadConfig());
      thunkAPI.dispatch(loadUsers());
    }
  }
)

export const checkUser: any = createAsyncThunk(
  'manageUsers/checkUser',
  async (params: {userId: string, state: boolean}, thunkAPI) => {
    const users = (thunkAPI.getState() as AppState).manageUsers.checkedUsers;
    if (params.state) {
      if (!users.find(uid => uid === params.userId)) {
        return [...users, params.userId];
      }
    } else {
      return [...users.filter(uid => params.userId !== uid)];
    }
  }
)

export const checkAllUsers: any = createAsyncThunk(
  'manageUsers/checkAllUsers',
  async (params: {state: boolean}, thunkAPI) => {
    const users = (thunkAPI.getState() as AppState).manageUsers.users;
    if (params.state) {
      return {
        checkedUsers: users.map(user => user.id),
        checkAllUsersChecked: params.state,
      };
    } else {
      return {
        checkedUsers: [],
        checkAllUsersChecked: params.state,
      }
    }
  }
)

const manageUsersSlice = createSlice({
  name: 'manageUsers',
  initialState: initialManageUsersState,
  reducers: {
    reset: () => ({...initialManageUsersState}),
    setSelectedTeam: (state, action) => ({...state, selectedTeam: action.payload, selectedOffice: undefined, selectedDepartment: undefined, selectedMisc: undefined}),
    setSelectedOffice: (state, action) => ({...state, selectedTeam: undefined, selectedOffice: action.payload, selectedDepartment: undefined, selectedMisc: undefined}),
    setSelectedDepartment: (state, action) => ({...state, selectedTeam: undefined, selectedOffice: undefined, selectedDepartment: action.payload, selectedMisc: undefined}),
    setSelectedMisc: (state, action) => ({...state, selectedTeam: undefined, selectedOffice: undefined, selectedDepartment: undefined, selectedMisc: action.payload}),
    setSortOption: (state, action) => ({...state, sortOption: action.payload}),
    setSearchQuery: (state, action) => ({...state, searchQuery: action.payload}),
    setCheckedUsers: (state, action) => ({...state, checkedUsers: action.payload}),
  },
  extraReducers: {
    [loadUsers.pending]: (state) => ({...state, desks: [], isLoading: true, users: []}),
    [loadUsers.rejected]: (state) => ({...state, isLoading: false}),
    [loadUsers.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      users: action.payload.users,
      skip: action.payload.skip,
      totalUsers: action.payload.total,
      checkedUsers: [],
    }),

    [assignUsersToTeam.pending]: (state) => ({...state, desks: [], isLoading: true}),
    [assignUsersToTeam.rejected]: (state) => ({...state, isLoading: false}),
    [assignUsersToTeam.fulfilled]: (state, action) => ({...state, isLoading: false}),

    [assignUsersToDepartment.pending]: (state) => ({...state, isLoading: true}),
    [assignUsersToDepartment.rejected]: (state) => ({...state, isLoading: false}),
    [assignUsersToDepartment.fulfilled]: (state, action) => ({...state, isLoading: false}),

    [loadDetails.fulfilled]: (state, action) => ({
      ...state,
      info: action.payload,
    }),

    [checkUser.fulfilled]: (state, action) => ({
      ...state,
      checkedUsers: action.payload,
    }),

    [checkAllUsers.fulfilled]: (state, action) => ({
      ...state,
      checkedUsers: action.payload.checkedUsers,
      checkAllUsersChecked: action.payload.checkAllUsersChecked
    }),
  }
})

export default manageUsersSlice.reducer;
export const {
  reset,
  setSelectedMisc,
  setSelectedTeam,
  setSelectedOffice,
  setSelectedDepartment,
  setSortOption,
  setSearchQuery,
  setCheckedUsers,
} = manageUsersSlice.actions;

// Selectors
export const selectUsers = (state: AppState) => state.manageUsers.users;
export const selectSelectedTeam = (state: AppState) => state.manageUsers.selectedTeam;
export const selectSelectedOffice = (state: AppState) => state.manageUsers.selectedOffice;
export const selectSelectedDepartment = (state: AppState) => state.manageUsers.selectedDepartment;
export const selectSelectedMisc = (state: AppState) => state.manageUsers.selectedMisc;
export const selectIsManageUsersLoading = (state: AppState) => state.manageUsers.isLoading;
export const selectSelectedSortOption = (state: AppState) => state.manageUsers.sortOption;
export const selectAllCheckedUsers = (state: AppState) => {
  const users = state.manageUsers.users;
  const checkedUsers = state.manageUsers.checkedUsers;
  return users.filter(u => checkedUsers.find(uid => u.id === uid));
}
export const selectInfo = (state: AppState) => state.manageUsers.info;
export const selectNumberOfUsersSkipped = (state: AppState) => state.manageUsers.skip;
export const selectTotalUsersInQuery = (state: AppState) => state.manageUsers.totalUsers;
export const selectTotalUsersReturned = (state: AppState) => state.manageUsers.users.length;
export const selectSearchQuery = (state: AppState) => state.manageUsers.searchQuery;
export const selectCheckedUsers = (state: AppState) => state.manageUsers.checkedUsers;
export const selectCheckAllUsersChecked = (state: AppState) => state.manageUsers.checkAllUsersChecked;

import React from 'react';
import styled from "styled-components/macro";

export function BirthdayIcon(props: Props) {
  return (
    <Image style={props?.style ?? {}} src={"/assets/images/birthday.svg"} alt={"Birthday"} />
  )
}

interface Props {
  style?: any;
}

const Image = styled.img`
  width: 48px;
  height: 48px;
  right: -12px;
  top: -12px;
  position: absolute;
`

import { CompanyEntity } from "./company.models";
import { TeamEntity, TeamWithCount } from "./team.models";
import { AuthType } from "../utils/AuthUtils";
import { Department } from "../components/pages/departments/data/departments.models";

export interface User {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  role: Permission;
  hasSlackEnabled: boolean;
  companyEntity: CompanyEntity;
  teamEntity: TeamEntity | TeamWithCount | any;
  runOnboarding: boolean;
  firstTime?: boolean;
  emailVerified: boolean;
  enableOutlookSync: boolean;
  authType: AuthType;
  allowWhereaboutsReminders: boolean;
  allowMarketingEmails: boolean;
  defaultLocationId: number;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
  holidayAllowance: boolean;
  lineManagementEnabled: boolean;
  whereaboutsEdited: boolean;
  carParkingEnabled: boolean;
  department?: Department;
  birthdayDay?: number;
  birthdayMonth?: number;
}

export interface BasicUserInfo {
  firstName: string;
  lastName: string;
  userId: string;
  role: Permission;
  teamId: string;
  departmentId?: number;
  teamName?: string;
  companyId: string;
  fireWarden: boolean;
  firstAider: boolean;
  jobTitle?: string;
  birthdayDay?: number;
  birthdayMonth?: number;
}

export interface Birthday {
  day: number;
  month: number;
}

export interface UserListItem {
  id: string;
  name: string;
}

export interface UnknownWhereaboutsUserInfo extends BasicUserInfo {
  lastNotifiedDate?: string;
  emailVerified: boolean;
}

export enum Permission {
  Basic = 'BASIC', TeamAdmin = 'TEAM_ADMIN', OfficeAdmin = 'OFFICE_ADMIN', CompanyAdmin = 'COMPANY_ADMIN', DepartmentAdmin = 'DEPARTMENT_ADMIN',
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  role: Permission;
  teamId: string;
  companyId: string;
  defaultLocationId: number;
  firstAider: boolean;
  fireWarden: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
  holidayAllowance: number;
  enableOutlookSync: boolean;
  accessPermitted: boolean;
  departmentId?: number;

  birthdayDay?: number;
  birthdayMonth?: number;
  jobTitle?: string;
}

export interface SimpleUser {
  firstName: string;
  lastName: string;
  userId: string;
  teamName: string;
  email: string;
  role: string;
  teamId?: string;
}

export interface SimpleUserWithResponsibilitiesAndLocation extends SimpleUser {
  officeId: number;
  pmOfficeId: number;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
}

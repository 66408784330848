import React, { useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { Switch } from "../../atoms/Switch";
import { updateCompanySetting } from "../../../../services/CompanyService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, updateCurrentUser } from "../../../../store/ducks/auth.duck";
import { failureNotification } from "../../../../store/ducks/notification.duck";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { useTranslation } from "react-i18next";

const FIELD = 'SHOW_BIRTHDAYS'

export function ShowBirthdaysOnWhereaboutsSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const showBirthdays = user?.companyEntity?.showBirthdays;
  const {t} = useTranslation();

  const onChange = async (value: boolean) => {
    try {
      setIsLoading(true);
      await updateCompanySetting(FIELD, value);
      dispatch(updateCurrentUser(true));
    } catch (e: any) {
      dispatch(failureNotification('Failed to update setting, please wait a minute.'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfigurationSection className={'domainBasedSignup__section'}>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.show-birthdays.title')}</BodyLarge>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!showBirthdays} label="" onChange={onChange}/>}
      </SpaceBetweenRow>
      <BodyRegular>{t('settings.show-birthdays.subtitle')}</BodyRegular>
    </ConfigurationSection>
  )
}

import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { BodySmall } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import TextField from "../../../../UI/atoms/TextField";
import { useDispatch } from "react-redux";
import { editHolidayAllowance } from "../../models/holiday-allowances.duck";

export function HolidayAllowanceContentCard(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [basic, setBasic] = useState('0');
  const [additional, setAdditional] = useState('0');

  useEffect(() => {
    setBasic(props.basicAllowance.toString());
  }, [props.basicAllowance]);

  useEffect(() => {
    setAdditional(props.additionalDays.toString());
  }, [props.additionalDays]);

  const updateAllowance = () => {
    dispatch(editHolidayAllowance({user: props.userId, year: props.year, basicAllowance: parseFloat(basic), additionalDays: parseFloat(additional)}))
  }

  return (
    <AllowanceContentCard edited={!!props?.edited}>
      <Row>
        <BodySmall weight={600} colour={props.disabled ? Colours.darkGrey : Colours.black}>{t('allowance.basic')}</BodySmall>
        <TextField onChange={(value: string) => setBasic(value)}
                   rightText={'allowance.justDays'}
                   onBlur={updateAllowance}
                   disabled={props?.disabled}
                   value={basic} />
      </Row>
      <Row style={{border: 'none'}}>
        <BodySmall weight={600} colour={props.disabled ? Colours.darkGrey : Colours.black}>{t('allowance.additional')}</BodySmall>
        <TextField onChange={(value: string) => setAdditional(value)}
                   rightText={'allowance.justDays'}
                   disabled={props?.disabled}
                   onBlur={updateAllowance}
                   value={additional} />
      </Row>
      <Row style={{backgroundColor: props.accentColour, border: 'none'}}>
        <BodySmall weight={600} colour={Colours.black}>{t('allowance.total')}</BodySmall>
        <BodySmall weight={600} colour={Colours.black}>{t('allowance.days', {days: props.totalDays})}</BodySmall>
      </Row>
      <Row>
        <BodySmall weight={600} colour={Colours.darkGrey}>{t('allowance.used')}</BodySmall>
        <BodySmall weight={600} colour={Colours.black}>{t('allowance.days', {days: props.daysUsed})}</BodySmall>
      </Row>
      <Row>
        <BodySmall weight={600} colour={Colours.darkGrey}>{t('allowance.remaining')}</BodySmall>
        <BodySmall weight={600} colour={Colours.black}>{t('allowance.days', {days: props.remainingDays})}</BodySmall>
      </Row>
    </AllowanceContentCard>
  )
}

interface Props {
  disabled?: boolean;
  accentColour: string;
  totalDays: number;
  daysUsed: number;
  remainingDays: number;
  additionalDays: number;
  basicAllowance: number;
  year: number;
  userId: string;
  edited?: boolean;
}

const AllowanceContentCard = styled.div<any>`
  width: 25%;
  min-height: 300px;
  border-radius: 12px;
  background-color: white;
  padding: 8px 12px 20px 12px;
  box-shadow: ${props => props.edited ? `inset 0 0 0 2px ${Colours.blue}` : 'none'};
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colours.veryLightGrey};
  padding: 8px 20px 8px 20px;
  border-radius: 12px;
  margin: 8px 0 8px 0;
  .textField {
    width: 100px;
    margin: 0 0 0 auto;
    line-height: 20px;
  }
  &:last-child {
    border-bottom: none;
  }
`

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge } from '../../../../UI/atoms/fonts/Headline';
import { selectCurrentUser } from '../../../../../store/ducks/auth.duck';
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { isCompanyAdminUser } from "../../../../../utils/AccessControl";
import AccessRequired from "../../components/AccessRequired";
import { useTranslation } from "react-i18next";
import { selectAllDepartments } from "../../../departments/data/departments.duck";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { Department } from "../../../departments/data/departments.models";
import { DepartmentContentCard } from "./components/DepartmentContentCard";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";

export default function ManageDepartmentsPage() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const company = current?.companyEntity;
  const departments = useSelector(selectAllDepartments);
  const hasAccessToManageDepartments = useMemo(() => isCompanyAdminUser(current), [current]);
  const {t} = useTranslation();

  const addNewDepartment = () => {
    dispatch(openDialogWithPayload({
      payload: { },
      activeDialog: DialogIdentifiers.EditDepartmentDialog
    }))
  }

  return (
    <>
      <GoBackButton url={'/manage'} />
      <HeadlineLarge className="teamList__header">{t('settings.departments.title')}</HeadlineLarge>
      <div className="manage__sections">

        <AccessRequired visible={!hasAccessToManageDepartments} companyAdminsRequired={true} />

          {departments.map((department: Department) => (<DepartmentContentCard department={department} />))}

      </div>
      {company?.enabled && <PrimaryButton size="medium"
                                          fullWidth={true}
                                          click={addNewDepartment}
                                          text={'settings.departments.add-new'} />}
    </>
  );
}

import React, { useState } from 'react';
import { BodyLarge, BodyRegular, BodyXXSmall } from "../../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { GreenPill } from "../../atoms/Pill";
import { HeadlineSmall } from "../../atoms/fonts/Headline";
import Colours from "../../atoms/Colours";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, updateCurrentUser } from "../../../../store/ducks/auth.duck";
import { SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { updateCompanySetting } from "../../../../services/CompanyService";

interface Props {
  style?: any;
}

export function AzureScimIntegrationMessage(props: Props) {
  const {t} = useTranslation();

  return (
    <div style={props?.style ?? {}} className={'azureScimIntegration'}>
      <GreenPill style={{marginBottom: 16}}><BodyXXSmall>{t('settings.azure-scim.medium')}</BodyXXSmall></GreenPill>
      <HeadlineSmall style={{marginBottom: 4}}>{t('settings.azure-scim.title')}</HeadlineSmall>
      <BodyRegular style={{marginBottom: 4}} weight={600}>{t('settings.azure-scim.subtitle')}</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.azure-scim.description')}</BodyRegular>
    </div>
  )
}

export function AzureScimIntegrationSettingMessage(props: Props) {
  const {t} = useTranslation();
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [isDepartmentsSettingLoading, setIsDepartmentsSettingLoading] = useState<boolean>(false);
  const [isWelcomeEmailLoading, setIsWelcomeEmailLoading] = useState<boolean>(false);
  const [isDisableNewUserAccessLoading, setIsDisableNewUserAccessLoading] = useState<boolean>(false);

  const onDepartmentsChange = async (value: boolean) => {
    try {
      setIsDepartmentsSettingLoading(true);
      await updateCompanySetting('SCIM_DEPARTMENTS', value);
      dispatch(updateCurrentUser(true));
    } finally {
      setIsDepartmentsSettingLoading(false);
    }
  }

  const onWelcomeEmailChange = async (value: boolean) => {
    try {
      setIsWelcomeEmailLoading(true);
      await updateCompanySetting('DISABLE_SCIM_WELCOME_EMAILS', !value);
      dispatch(updateCurrentUser(true));
    } finally {
      setIsWelcomeEmailLoading(false);
    }
  }

  const onDisableNewUserAccess = async (value: boolean) => {
    try {
      setIsDisableNewUserAccessLoading(true);
      await updateCompanySetting('DISABLE_NEW_SCIM_USER_ACCESS', !value);
      dispatch(updateCurrentUser(true));
    } finally {
      setIsDisableNewUserAccessLoading(false);
    }
  }

  return (
    <div style={props?.style ?? {}} className={'azureScimIntegration'}>
      <BodyLarge colour={Colours.blue} style={{marginBottom: 4}}>{t('settings.azure-scim.title')}</BodyLarge>
      <BodyRegular style={{marginBottom: 4}} weight={600}>{t('settings.azure-scim.subtitle')}</BodyRegular>
      <BodyRegular style={{marginBottom: 32}}>{t('settings.azure-scim.description')}</BodyRegular>

      <SpaceBetweenRow>
        <BodyRegular weight={600} colour={Colours.blue}>{t('settings.azure-scim.enabled-departments')}</BodyRegular>
        {isDepartmentsSettingLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.departmentFromScim} label="" onChange={onDepartmentsChange}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginTop: -8, marginBottom: 32}}>{t('settings.azure-scim.enabled-departments-description')}</BodyRegular>

      <SpaceBetweenRow>
        <BodyRegular weight={600} colour={Colours.blue}>{t('settings.azure-scim.disable-welcome-email')}</BodyRegular>
        {isWelcomeEmailLoading ? <SimpleSpinner size={32} /> : <Switch value={!user?.companyEntity.disableNewScimUserEmail} label="" onChange={onWelcomeEmailChange}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginTop: -8, marginBottom: 24}}>{t('settings.azure-scim.disable-welcome-email-description')}</BodyRegular>

      <SpaceBetweenRow>
        <BodyRegular weight={600} colour={Colours.blue}>{t('settings.azure-scim.disable-new-user-access')}</BodyRegular>
        {isDisableNewUserAccessLoading ? <SimpleSpinner size={32} /> : <Switch value={!user?.companyEntity.disableNewScimUserAccessToLogin} label="" onChange={onDisableNewUserAccess}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginTop: -8, marginBottom: 24}}>{t('settings.azure-scim.disable-new-user-access-description')}</BodyRegular>
    </div>
  )
}
